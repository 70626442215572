import { FC, ReactNode } from "react";
import { isProductionMode } from "@/utils";
type Props = {
  children: ReactNode;
};
export const DevOnly: FC<Props> = ({
  children
}: Props) => {
  if (isProductionMode) return null;
  return <>{children}</>;
};
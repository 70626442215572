import Link from 'next/link';
import type { FC } from 'react';
type ExternalLinkProps = {
  title?: string;
};
const ExternalLink: FC<ExternalLinkProps> = ({
  title
}) => {
  let href = title;
  if (!href) {
    return null;
  }
  if (!href.includes('://')) {
    href = `https://${href}`;
  }
  return <Link href={href} onClick={event => {
    event.stopPropagation();
  }} rel="noopener" target={href.includes(location.host) ? '_self' : '_blank'} data-sentry-element="Link" data-sentry-component="ExternalLink" data-sentry-source-file="ExternalLink.tsx">
      {title}
    </Link>;
};
export default ExternalLink;
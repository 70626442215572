import React from "react";
import Link from "next/link";

// mui imports
import { ListItemIcon, ListItem, List, styled, ListItemText, useTheme } from "@mui/material";
import { useSelector } from "../../../../../store/Store";
import { AppState } from "../../../../../store/Store";
type NavGroup = {
  [x: string]: any;
  id?: string;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: string;
  children?: NavGroup[];
  chip?: string;
  chipColor?: any;
  variant?: string | any;
  external?: boolean;
  level?: number;
};
interface ItemType {
  item: NavGroup;
  onClick?: React.MouseEventHandler<HTMLElement>;
  hideMenu: any;
  level?: number | any;
  pathDirect: string;
}
const NavItem = ({
  item,
  level,
  pathDirect,
  onClick
}: ItemType) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const Icon = item.icon;
  const theme = useTheme();
  const itemIcon = level > 1 ? <Icon stroke={1.5} size="1rem" /> : <Icon stroke={1.5} size="1.1rem" />;
  const ListItemStyled2 = styled(ListItem)(() => ({
    padding: "5px 10px",
    gap: "10px",
    borderRadius: `${customizer.borderRadius}px`,
    marginBottom: level > 1 ? "3px" : "0px",
    color: level > 1 && pathDirect === item.href ? `${theme.palette.primary.main}!important` : theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.primary.light
    },
    "&.Mui-selected": {
      color: level > 1 ? theme.palette.primary.main : "white!important",
      backgroundColor: level > 1 ? "transparent" : theme.palette.primary.main,
      "&:hover": {
        backgroundColor: level > 1 ? "" : theme.palette.primary.main,
        color: "white"
      }
    }
  }));
  const listItemProps: {
    component: any;
    href?: string;
    target?: any;
    to?: any;
  } = {
    component: item?.external ? "a" : Link,
    to: item?.href,
    href: item?.href,
    target: item?.external ? "_blank" : ""
  };
  return <List component="li" disablePadding key={item.id} data-sentry-element="List" data-sentry-component="NavItem" data-sentry-source-file="NavItem.tsx">
      <ListItemStyled2 {...listItemProps} disabled={item.disabled} selected={pathDirect === item.href} onClick={onClick} data-sentry-element="ListItemStyled2" data-sentry-source-file="NavItem.tsx">
        <ListItemIcon sx={{
        minWidth: "auto",
        p: "3px 0",
        color: "inherit"
      }} data-sentry-element="ListItemIcon" data-sentry-source-file="NavItem.tsx">
          {itemIcon}
        </ListItemIcon>
        <ListItemText data-sentry-element="ListItemText" data-sentry-source-file="NavItem.tsx">{item.title}</ListItemText>
      </ListItemStyled2>
    </List>;
};
export default NavItem;
import type { FC, ReactNode } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
// @ts-expect-error
import linkifyRegex from 'remark-linkify-regex';
import stripMarkdown from 'strip-markdown';
import MarkupLink from './markupLink';
import { Regex } from './regex';

/**
 * Trims a string and removes excess newlines.
 */
const trimify = (value: string): string => value?.replace(/\n\n\s*\n/g, '\n\n').trim();
const plugins = [[stripMarkdown, {
  keep: ['strong', 'emphasis', 'inlineCode', 'delete']
}], remarkBreaks, linkifyRegex(Regex.url), linkifyRegex(Regex.mention)];
interface MarkupProps {
  children: ReactNode;
  className?: string;
  mentions?: any[];
}
const Markup: FC<MarkupProps> = ({
  children,
  className = '',
  mentions = []
}) => {
  if (!children) {
    return null;
  }
  const components = {
    a: (props: any) => {
      return <MarkupLink mentions={mentions} title={props.title} />;
    }
  };
  return <ReactMarkdown className={className} components={components} remarkPlugins={plugins} data-sentry-element="ReactMarkdown" data-sentry-component="Markup" data-sentry-source-file="index.tsx">
      {trimify(children as string)}
    </ReactMarkdown>;
};
export default Markup;
export interface userType {
  id: string
  avatar: string
  name: string
  role: string
  country: string
  isFollowed: boolean
}

export interface GallaryType {
  id: string
  cover: string
  name: string
  time: string
}

export type UserSession = {
  address: `0x${string}` | undefined
  profileId: string | undefined
  handle: string | undefined
  name: string | undefined
  bio: string | undefined
  picture: string | undefined
  coverPicture: string | undefined
  followers: number
  following: number
  posts: number
}

export const defaultUserSession = {
  address: undefined,
  profileId: undefined,
  handle: undefined,
  name: undefined,
  bio: undefined,
  picture: undefined,
  coverPicture: undefined,
  followers: 0,
  following: 0,
  posts: 0,
}

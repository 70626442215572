import React, { useState } from 'react';
import { Box, Button, Accordion, AccordionSummary, AccordionDetails, Typography, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CategoryType } from '@/types/custom';
import { useCategoryFilter } from '@/context/categoryFilter';
type CategoriesDropdownFilterProps = {
  categories: CategoryType[];
};
const CategoriesDropdownFilter = ({
  categories
}: CategoriesDropdownFilterProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const {
    selectedPeopleCategories,
    handleStoreCategories,
    handleRemoveCategories
  } = useCategoryFilter();
  const selectedCategoriesIds = selectedPeopleCategories.map(c => c.id);
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const handleCategoryUpdate = async (category: CategoryType) => {
    if (selectedCategoriesIds.includes(category.id)) {
      await handleRemoveCategories([category.id], 'people');
    } else {
      const selectedCategoriesToStore = [...selectedPeopleCategories, category];
      await handleStoreCategories(selectedCategoriesToStore, 'people');
    }
  };
  return <Box data-sentry-element="Box" data-sentry-component="CategoriesDropdownFilter" data-sentry-source-file="CategoryFilter.tsx">
      <Accordion expanded={expanded} onChange={handleToggle} data-sentry-element="Accordion" data-sentry-source-file="CategoryFilter.tsx">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{
        backgroundColor: theme => theme.palette.primary.light,
        borderRadius: '0.5em'
      }} data-sentry-element="AccordionSummary" data-sentry-source-file="CategoryFilter.tsx">
          <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="CategoryFilter.tsx">
            {expanded ? 'Select Categories' : 'Personalize content'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails data-sentry-element="AccordionDetails" data-sentry-source-file="CategoryFilter.tsx">
          <Box display="grid" flexWrap={{
          xs: 'wrap'
        }} gridTemplateColumns={{
          xs: 'repeat(2, 1fr)',
          md: 'repeat(4, 1fr)'
        }} gap={1} data-sentry-element="Box" data-sentry-source-file="CategoryFilter.tsx">
            {categories.map(category => <Button key={category.id} variant={selectedCategoriesIds.includes(category.id) ? 'contained' : 'outlined'} size="small" onClick={() => handleCategoryUpdate(category)} sx={{
            lineHeight: 1.5
          }}>
                {category.name}
              </Button>)}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>;
};
export default CategoriesDropdownFilter;
import { SxProps, useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography, Stack, Box, useMediaQuery, Theme, Avatar, Grid } from '@mui/material';
import { AppState, useSelector } from '@/store/Store';
type Props = {
  title?: string;
  titleSize?: number;
  titleImg?: string;
  titleEmoji?: string;
  subtitle?: string;
  titleSubAction?: JSX.Element | any;
  filter?: JSX.Element | any;
  action?: JSX.Element | any;
  footer?: JSX.Element;
  cardheading?: string | JSX.Element;
  headtitle?: string | JSX.Element;
  headsubtitle?: string | JSX.Element;
  children?: JSX.Element;
  middlecontent?: string | JSX.Element;
  sxContent?: SxProps;
  contentPadding?: string;
  sxMainCard?: SxProps;
  sxTitle?: SxProps;
  headerProps?: any;
};
const PeopleDashboardCard = ({
  title,
  titleSize,
  titleImg,
  titleEmoji,
  subtitle,
  children,
  titleSubAction,
  filter,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
  sxTitle,
  headerProps,
  sxContent,
  contentPadding,
  sxMainCard
}: Props) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const theme = useTheme();
  const borderColor = theme.palette.divider;
  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const TitleContent = () => <Box display="flex" alignItems="center" padding={contentPadding ? contentPadding : '0'} data-sentry-element="Box" data-sentry-component="TitleContent" data-sentry-source-file="PeopleDashboardCard.tsx">
      {titleImg && <Avatar src={titleImg} alt="Icon" style={{
      marginRight: 8
    }} />}
      <Box data-sentry-element="Box" data-sentry-source-file="PeopleDashboardCard.tsx">
        <Typography variant="h5" fontSize={titleSize} sx={{
        ...sxTitle,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }} data-sentry-element="Typography" data-sentry-source-file="PeopleDashboardCard.tsx">
          {title} {titleEmoji}
        </Typography>
        {subtitle && <Typography variant="subtitle2" color="textSecondary">
            {subtitle}
          </Typography>}
      </Box>
    </Box>;
  return <Card sx={{
    padding: 0,
    border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
    ...sxMainCard
  }} elevation={customizer.isCardShadow ? 9 : 0} variant={!customizer.isCardShadow ? 'outlined' : undefined} data-sentry-element="Card" data-sentry-component="PeopleDashboardCard" data-sentry-source-file="PeopleDashboardCard.tsx">
      {cardheading ? <CardContent>
          <Typography variant="h5">{headtitle}</Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {headsubtitle}
          </Typography>
        </CardContent> : <CardContent sx={{
      padding: contentPadding ? contentPadding : '30px',
      ...sxContent
    }}>
          {title && <>
              {isMdScreen ? <Stack spacing={2} mb={contentPadding ? 2 : 3}>
                  <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}>
                    <TitleContent />
                    {action}
                  </Box>
                  <Box sx={{
            width: '100%'
          }}>{filter}</Box>
                </Stack> : <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" mb={contentPadding ? 2 : 3} {...headerProps}>
                  <Grid container spacing={4} alignItems="center" sx={{
            flexWrap: 'nowrap',
            minWidth: 0
          }}>
                    <Grid item sx={{
              flexShrink: 0,
              maxWidth: 'fit-content'
            }}>
                      <TitleContent />
                    </Grid>

                    <Grid item sx={{
              flexGrow: 1,
              minWidth: 0
            }}>
                      {filter}
                    </Grid>

                    <Grid item sx={{
              flexShrink: 0,
              marginLeft: 'auto'
            }}>
                      {action}
                    </Grid>
                  </Grid>
                </Stack>}
            </>}

          {children}
        </CardContent>}

      {middlecontent}
      {footer}
    </Card>;
};
export default PeopleDashboardCard;
// ** Hooks
import { useState } from 'react';
import { useReadContract, useWriteContract } from 'wagmi';

// Utils & Types
import { erc20Abi } from 'viem';
import { Contract } from 'ethers';
import { useEthersProvider } from './useEthersAdapters';
import { useHandleNetwork } from '@/context/network';
import { useSession } from '@/context/session';
type WriteFnNames = 'approve' | 'transfer';
export function useERC20(address?: string | undefined, recepient?: string | undefined) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    writeContractAsync
  } = useWriteContract();
  const {
    user
  } = useSession();
  const {
    chainId
  } = useHandleNetwork();
  const provider = useEthersProvider({
    chainId
  });

  /*----------------------------------------------------------*|
  |*  # Get symbol                                            *|
  |*----------------------------------------------------------*/

  const {
    data: symbol,
    isError: isErrorSymbol,
    isLoading: isLoadingSymbol
  } = useReadContract({
    address: address as `0x`,
    abi: erc20Abi,
    functionName: 'symbol'
  });

  /*----------------------------------------------------------*|
  |*  # Get decimals                                          *|
  |*----------------------------------------------------------*/

  const {
    data: decimals,
    isError: isErrorDecimals,
    isLoading: isLoadingDecimals
  } = useReadContract({
    address: address as `0x`,
    abi: erc20Abi,
    functionName: 'decimals'
  });

  /*----------------------------------------------------------*|
  |*  # Get balance                                           *|
  |*----------------------------------------------------------*/

  const {
    data: balance,
    isError: isErrorBalance,
    isLoading: isLoadingBalance
  } = useReadContract({
    address: address as `0x`,
    abi: erc20Abi,
    functionName: 'balanceOf',
    args: [recepient as `0x`]
  });
  const checkEnoughAllowance = async (spender: `0x${string}`, spendAmount: number) => {
    try {
      if (!address) throw new Error('Token not found.');
      const erc20Contract = new Contract(address, erc20Abi, provider);
      const allowance = await erc20Contract.allowance(user.address, spender);
      return Number(allowance) >= spendAmount;
    } catch (error) {
      console.log('Error checking token allowance.', error);
      return false;
    }
  };
  const executeAction = async (functionName: WriteFnNames, ...args: any) => {
    try {
      setIsLoading(true);
      console.log(`Executing ${functionName} action.`);
      return await writeContractAsync({
        abi: erc20Abi,
        address: address as `0x${string}`,
        functionName,
        args
      });
    } catch (error) {
      console.log(`Error executing ${functionName} onchain: `, error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };
  return {
    symbol,
    isErrorSymbol,
    isLoadingSymbol,
    balance,
    isErrorBalance,
    isLoadingBalance,
    decimals,
    isErrorDecimals,
    isLoadingDecimals,
    isActionLoading: isLoading,
    approve: (spender: string, amount: number) => executeAction('approve', spender, BigInt(amount)),
    transfer: (receiver: string, amount: number) => executeAction('transfer', receiver, BigInt(amount)),
    checkEnoughAllowance
  };
}
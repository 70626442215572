// ** React & MUI
import { useEffect, useMemo, useState } from 'react';
import { Modal, Button, Stack, CircularProgress, Box, Typography, Divider } from '@mui/material';

// ** Components
import BaseModal from './base';

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals';
import { BASE_ID } from '@/utils/constants/networks';
import { useSession } from '@/context/session';
import { usePublicClient, useSwitchChain } from 'wagmi';
import { formatEther, parseEther } from 'viem';
import { StatusEnum as Status } from '@/types/custom';
import { IconCheck, IconX } from '@tabler/icons-react';
import { usePods } from '@/context/pods';
import { useHandleNetwork } from '@/context/network';

/**
 * Payload for the Collect modal
 * @param podAddress - The contractAddress of the pod to be collected.
 * @param tokenId - The tokenId address of the pod to be collected.
 */
export interface PodCollectPayload {
  podAddress: `0x${string}`;
  tokenId: string;
  mintReferral?: `0x${string}`;
  onSuccess: () => void;
}

/////////////////////////////////////
export const PodCollectModal = () => {
  const publicClient = usePublicClient();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [collectCosts, setCollectCosts] = useState<any>();
  const [insufficientBalance, setInsufficientBalance] = useState<boolean>(false);
  const [parsedAmount, setParsedAmount] = useState<number>();
  const [collectAddress, setCollectAddress] = useState<`0x${string}`>();
  const [tokenId, setTokenId] = useState<string>('');
  const [status, setStatus] = useState<Status>(Status.idle);
  const [referralAddress, setReferralAddress] = useState<`0x${string}`>();
  const isSubmitSuccess = status === Status.success;
  const isSubmitError = status === Status.error;
  const isSubmitting = status === Status.loading;
  const {
    close
  } = useModalsActions();
  const {
    podCollect: collectModal
  } = useModalsState();
  const {
    mint,
    fetchMintPrice
  } = usePods();
  const {
    user
  } = useSession();
  const {
    switchChainAsync
  } = useSwitchChain();
  const {
    chainId
  } = useHandleNetwork();
  const {
    isOpen,
    data: modalData
  } = collectModal;

  /*************************************************
   *                    Render                      *
   *************************************************/

  useEffect(() => {
    if (!isOpen || !modalData) return;
    const {
      podAddress,
      tokenId,
      mintReferral
    } = modalData;
    getCollectData(podAddress, tokenId, mintReferral);
    return cleanState;
  }, [modalData, isOpen]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const getCollectData = async (podAddress: `0x${string}`, tokenId: string, mintReferral?: `0x${string}`) => {
    setIsLoading(true);
    await checkBaseNetwork();
    try {
      if (!user.address) return;
      const tokenPrice = await fetchMintPrice(podAddress);
      const balance = await publicClient?.getBalance({
        address: user.address
      });
      if (Number(balance) < Number(tokenPrice)) {
        setInsufficientBalance(true);
      }
      const parsedCostAmount = formatEther(tokenPrice);
      setReferralAddress(mintReferral);
      setCollectAddress(podAddress);
      setTokenId(tokenId);
      setCollectCosts(tokenPrice);
      setParsedAmount(Number(parsedCostAmount));
    } catch (error) {
      console.log('Error while running collect criteria function: ', error);
    } finally {
      setIsLoading(false);
    }
  };
  const cleanState = () => {
    setReferralAddress(undefined);
    setCollectCosts(undefined);
    setParsedAmount(undefined);
    setInsufficientBalance(false);
    setStatus(Status.idle);
  };
  const handleCloseModal = () => {
    close('podCollect');
  };
  const checkBaseNetwork = async () => {
    if (Number(chainId) === BASE_ID) return true;else {
      try {
        const result = await switchChainAsync({
          chainId: BASE_ID
        });
        if (result.id === BASE_ID) {
          return true;
        }
      } catch (error) {
        console.log('Error switching to Base network', error);
        return false;
      }
    }
  };
  const handleSubmit = async () => {
    try {
      setStatus(Status.loading);
      if (!user.address) throw new Error('User address not found');
      if (!collectAddress) throw new Error('Contract address not found');
      const isConnected = await checkBaseNetwork();
      if (!isConnected) return;
      const mintTx = await mint(collectAddress, tokenId, user.address, referralAddress);
      if (!mintTx) {
        setStatus(Status.error);
      } else {
        setStatus(Status.success);
        if (collectModal.data?.onSuccess) collectModal.data.onSuccess();
      }
    } catch (err) {
      console.log('Error collecting publication', err);
      setStatus(Status.error);
    } finally {
      setTimeout(() => {
        handleCloseModal();
      }, 1000);
    }
  };
  const submitLabel = useMemo(() => {
    if (status === Status.loading) return <CircularProgress size={24} />;
    if (status === Status.success) return <IconCheck size={24} />;
    if (status === Status.error) return <IconX size={20} />;
    return 'Collect';
  }, [status]);
  return <Modal open={collectModal.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-component="PodCollectModal" data-sentry-source-file="pod-collect.tsx">
      <BaseModal sx={{
      justifyContent: 'center'
    }} data-sentry-element="BaseModal" data-sentry-source-file="pod-collect.tsx">
        {isLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
            <CircularProgress />
          </Box> : <Stack spacing={4}>
            <Stack gap={0.4}>
              <Typography variant="h5" textAlign="center">
                Collect details
              </Typography>
              <Divider sx={{
            mt: '0.5em'
          }} />
              {collectCosts && <>
                  <Stack direction="row" gap={0.5} mt={1}>
                    <Typography variant="subtitle2" sx={{
                fontWeight: 'bold'
              }}>
                      Collect total costs:
                    </Typography>
                    <Typography variant="subtitle2">{`${parsedAmount} ETH`}</Typography>
                  </Stack>
                </>}
              {insufficientBalance ? <Stack direction="row" gap={0.5}>
                  <Typography variant="subtitle2" color="error" sx={{
              fontWeight: 'bold'
            }}>
                    Insufficient balance
                  </Typography>
                </Stack> : null}
            </Stack>
            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
              <Button color="success" variant="contained" size="large" disabled={isSubmitting || isSubmitError || isSubmitSuccess || insufficientBalance} onClick={handleSubmit}>
                {submitLabel}
              </Button>
              <Button color="error" size="large" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Stack>
          </Stack>}
      </BaseModal>
    </Modal>;
};
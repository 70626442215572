// ** React & Next Imports
import { useEffect } from 'react';
import Link from 'next/link';

// ** MUI Imports
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';

// ** State Imports
import { useModalsActions, useModalsState } from '@/context/modals';
import BaseModal from './base';

/**
 * Payload for the error modal
 */
export interface ErrorPayload {
  /**
   * The title to display.
   * @default "Something went wrong"
   */
  title?: string;
  /**
   * The message to display.
   * @default "Please try again later"
   */
  message?: string;
  /**
   * Whether the modal should close automatically after few seconds.
   */
  autoClose?: boolean;
  /**
   * Label to display at primary button.
   */
  primaryBtnLabel?: string;
  /**
   * Function executed after on clicking primary button.
   */
  primaryBtnCallback?: () => Promise<void> | void;
  /**
   * Label to display at secondary button.
   */
  secondaryBtnLabel?: string;
  /**
   * Function executed after on clicking secondary button.
   */
  secondaryBtnCallback?: () => Promise<void> | void;
}

//////////////////////////////////
export const ErrorModal = () => {
  const {
    error
  } = useModalsState();
  const {
    isOpen,
    data
  } = error;
  const {
    close
  } = useModalsActions();

  /*************************************************
   *                  Effects                      *
   *************************************************/

  useEffect(() => {
    if (isOpen && data?.autoClose) {
      setTimeout(() => {
        handleCloseModal();
      }, 5500);
    }
  }, [isOpen]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const handleCloseModal = () => close('error');
  const handlePrimaryBtn = async () => {
    if (data?.primaryBtnCallback) {
      await data.primaryBtnCallback();
    }
    handleCloseModal();
  };
  const handleSecondaryBtn = async () => {
    if (data?.secondaryBtnCallback) {
      await data.secondaryBtnCallback();
    }
    handleCloseModal();
  };
  return <Modal open={isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-component="ErrorModal" data-sentry-source-file="error.tsx">
      <BaseModal sx={{
      alignItems: 'center'
    }} data-sentry-element="BaseModal" data-sentry-source-file="error.tsx">
        <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }} data-sentry-element="Box" data-sentry-source-file="error.tsx">
          <Typography variant="h6" component="h2" data-sentry-element="Typography" data-sentry-source-file="error.tsx">
            {data?.title || 'Something went wrong'}
          </Typography>
          <Divider sx={{
          width: '100%',
          my: 0.1
        }} data-sentry-element="Divider" data-sentry-source-file="error.tsx" />
          <Typography variant="body1" component="p" textAlign={'center'} sx={{
          my: 2
        }} data-sentry-element="Typography" data-sentry-source-file="error.tsx">
            {data?.message || 'Please try again later'}
          </Typography>
          <Stack direction={'row'} gap={2} data-sentry-element="Stack" data-sentry-source-file="error.tsx">
            <Button onClick={handlePrimaryBtn} data-sentry-element="Button" data-sentry-source-file="error.tsx">
              {data?.primaryBtnLabel || 'Accept'}
            </Button>
            {(data?.primaryBtnCallback || data?.secondaryBtnCallback) && <Button color="error" onClick={handleSecondaryBtn}>
                {data?.secondaryBtnLabel || 'Later'}
              </Button>}
          </Stack>
        </Box>
      </BaseModal>
    </Modal>;
};
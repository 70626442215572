// ** React, Next & MUI
import Image from 'next/image';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Modal, Button, Stack, CircularProgress, Box, Typography, useTheme, Divider, TextField, useMediaQuery, MenuItem } from '@mui/material';

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals';
import { useLens } from '@/context/lens';
import { useIPFSStorage } from '@/context/IPFSStorage';
import { useSession } from '@/context/session';

// ** Components
import BaseModal from './base';
import CustomSwitch from '../forms/theme-elements/CustomSwitch';
import CustomFormLabel from '../forms/theme-elements/CustomFormLabel';
import CustomSelect from '../forms/theme-elements/CustomSelect';

// ** Utils & Types
import { appendMentionPrefix, base64ToFile } from '@/utils/helpers';
import { getIPFSGateway } from '@/utils/constants/api';
import { IconCheck, IconSettings, IconX } from '@tabler/icons-react';
import { backendCurrencies, ZoraCurrency } from '@/utils/constants/types';
import { StatusEnum as Status } from '@/types/custom';

/**
 * Payload for the Share Stats modal
 * @param screenShoot - The ScreenShoot to be shared.
 */
export interface ShareStatsPayload {
  type: string;
  title?: string;
  payload: {
    screenShoot: string;
    engagements?: any[];
    engagementType?: string;
  };
}

/////////////////////////////////////
export const ShareStatsModal = () => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    close
  } = useModalsActions();
  const {
    shareStats
  } = useModalsState();
  const {
    isOpen,
    data: shareData
  } = shareStats;
  const title = shareData?.title ?? 'Share your stats!';
  const {
    loggedIn
  } = useSession();
  const {
    user,
    client: lensClient,
    createImageMetadata,
    createPost: createLensPost,
    createCollectablePost
  } = useLens();
  const {
    uploadJSON,
    uploadImage
  } = useIPFSStorage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.idle);
  const [screenShoot, setScreenShoot] = useState<string | null>(null);
  const [input, setInput] = useState<string>('');
  const [isCollectable, setIsCollectable] = useState<boolean>(false);
  const [isChargeable, setIsChargeable] = useState<boolean>(false);
  const [collectablePrice, setCollectablePrice] = useState<number>(1);
  const [currency, setCurrency] = useState<ZoraCurrency>(backendCurrencies[0]);
  const isSubmitting = status === Status.loading;
  const isSuccessSubmit = status === Status.success;
  useEffect(() => {
    if (!isOpen) return;
    handleData(shareData);
    return cleanState;
  }, [isOpen, shareData]);

  /*************************************************
   *                  Handlers                     *
   *************************************************/

  const handleData = (data?: ShareStatsPayload) => {
    try {
      setIsLoading(true);
      if (!data) throw new Error('No data provided');
      const {
        payload
      } = data;
      if (payload?.screenShoot) {
        setScreenShoot(payload?.screenShoot);
      }
      if (payload?.engagements?.length) {
        const engagementMentions = payload.engagements.map(engagement => `@${engagement.handle.localName}`).join(' ');
        if (payload?.engagementType === 'inbound') {
          setInput(`A big thank you to my greatest supporters this week!🎉: ${engagementMentions}`);
        } else {
          setInput(`Check out who I have been supporting this week! 💪🏼: ${engagementMentions}`);
        }
      }
    } catch (error) {
      console.log(`Error getting data: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };
  const cleanState = () => {
    setInput('');
    setScreenShoot(null);
  };
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      value
    } = event.target;
    if (name === 'input') setInput(value);
    if (name === 'collectablePrice') setCollectablePrice(Number(value));
  };
  const handleCloseModal = () => {
    close('shareStats');
  };
  const handleSubmit = async () => {
    try {
      setStatus(Status.loading);
      if (!loggedIn || !user.profileId || !user.address) throw new Error('User must be signed-in to publish.');
      if (!lensClient) throw new Error('Need to initialize lens client first.');
      const processedText = appendMentionPrefix(input);
      if (!screenShoot) throw new Error('No screenshot provided');
      const parsedImage = base64ToFile(screenShoot, 'statsScreenShoot');
      const imageCid = await uploadImage(parsedImage);
      if (!imageCid) throw new Error('Error uploading image to IPFS');
      const imageMetadata = getIPFSGateway(imageCid);
      const postMetadata = await createImageMetadata('My stats', imageMetadata, 'ShareNFT', processedText);
      if (postMetadata === null) throw new Error('Error creating metadata');
      const metadataCID = await uploadJSON(postMetadata);
      if (!metadataCID) throw new Error('Error uploading metadata to IPFS');
      if (isCollectable) {
        const createdPost = await createCollectablePost(metadataCID, collectablePrice, currency.currency as `0x`, user.address);
        if (!createdPost || createdPost.isFailure() || createdPost.value.__typename === 'LensProfileManagerRelayError') {
          throw new Error('Error creating collectable post on Lens');
        }
      } else {
        const createdPost = await createLensPost(metadataCID);
        if (!createdPost || createdPost.isFailure() || createdPost.value.__typename === 'LensProfileManagerRelayError') {
          throw new Error('Error creating post on Lens');
        }
      }
      setStatus(Status.success);
    } catch (err) {
      console.log(`Error sharing stats: ${err}`);
      setStatus(Status.error);
    } finally {
      if (status === Status.error) return;
      setTimeout(() => {
        handleCloseModal();
      }, 1500);
    }
  };
  const handleCurrencyChange = (event: any) => {
    const currency = backendCurrencies.find(currency => currency.currency === event.target.value);
    if (currency) setCurrency(currency);
  };
  const submitLabel = useMemo(() => {
    if (status === Status.loading) return <CircularProgress size={20} />;
    if (status === Status.success) return <IconCheck size={20} />;
    if (status === Status.error) return <IconX size={20} />;
    return 'Post';
  }, [status]);
  return <Modal open={shareStats.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-component="ShareStatsModal" data-sentry-source-file="share-stats.tsx">
      <BaseModal title={title} sx={{
      justifyContent: 'center',
      overflowY: 'hidden',
      alignContent: isXsScreen ? 'flex-start' : undefined,
      width: isXsScreen ? '80vw' : undefined,
      maxHeight: '100vh',
      maxWidth: isXsScreen ? '90vw' : '50vw'
    }} data-sentry-element="BaseModal" data-sentry-source-file="share-stats.tsx">
        {isLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
            <CircularProgress />
          </Box> : <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Stack spacing={{
          xs: 1,
          md: 2
        }}>
              <Box>
                <Stack>
                  <Box sx={{
                width: '100%',
                height: 'auto',
                maxHeight: '50vh',
                overflow: 'hidden',
                my: {
                  xs: 1,
                  md: 2
                },
                border: '2px dashed',
                borderColor: theme.palette.grey[300],
                borderRadius: '10px',
                padding: '1em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                    {screenShoot && <Image src={screenShoot} alt="Chart screenshot" layout="intrinsic" width={isMdScreen ? 350 : 450} height={isMdScreen ? 200 : 300} objectFit="contain" />}
                  </Box>
                  <TextField id="hey-comment" name="input" label="Your thoughts..." multiline size="medium" rows={2} value={input} onChange={handleInputChange} disabled={isSubmitting || isSuccessSubmit} />
                </Stack>

                <Divider sx={{
              my: '1em'
            }} />
                <Stack direction={'row'} alignItems={'center'} gap={1} mb={1}>
                  <IconSettings size="20" />
                  <Typography variant="h6">Collect settings</Typography>
                </Stack>
                <Box sx={{
              paddingLeft: '1em'
            }}>
                  <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box>
                      <Typography variant="body1" color={isCollectable ? 'primary' : 'textSecondary'} fontWeight={600} sx={{
                    lineHeight: '1'
                  }}>
                        {'Enable collect'}
                      </Typography>

                      <Typography variant="caption" color="textSecondary" marginTop={0} sx={{
                    lineHeight: '1'
                  }}>
                        {isCollectable ? 'This post can be collected' : 'This post cannot be collected'}
                      </Typography>
                    </Box>
                    <CustomSwitch onChange={() => setIsCollectable(!isCollectable)} checked={isCollectable} disabled={isSubmitting || isSuccessSubmit} />
                  </Stack>

                  {isCollectable ? <Box>
                      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box>
                          <Typography variant="body1" color={isChargeable ? 'primary' : 'textSecondary'} fontWeight={600} sx={{
                      lineHeight: '1'
                    }}>
                            {'Charge for collecting'}
                          </Typography>

                          <Typography variant="caption" color="textSecondary" marginTop={0} sx={{
                      lineHeight: {
                        xs: '0.8',
                        md: '1.5'
                      }
                    }}>
                            {isChargeable ? 'Get paid whenever someone collects your post' : 'Your post will be free to collect'}
                          </Typography>
                        </Box>
                        <CustomSwitch onChange={() => setIsChargeable(!isChargeable)} checked={isChargeable} disabled={isSubmitting || isSuccessSubmit} />
                      </Stack>
                      {isChargeable ? <Stack direction={'row'} gap={1}>
                          <Box width={'50%'}>
                            <CustomFormLabel sx={{
                      marginTop: '10px',
                      lineHeight: '1'
                    }}>
                              Price
                            </CustomFormLabel>
                            <TextField type="number" name="collectablePrice" size="medium" fullWidth value={collectablePrice} onChange={handleInputChange} disabled={isSubmitting || isSuccessSubmit} InputProps={{
                      inputProps: {
                        min: 0
                      }
                    }} />
                          </Box>
                          <Box width={'50%'}>
                            <CustomFormLabel sx={{
                      marginTop: '10px',
                      lineHeight: '1'
                    }}>
                              Currency
                            </CustomFormLabel>
                            <CustomSelect fullWidth name="currency" value={currency.currency} onChange={handleCurrencyChange} disabled={isSubmitting || isSuccessSubmit} MenuProps={{
                      style: {
                        maxHeight: '250px'
                      },
                      PaperProps: {
                        style: {
                          maxHeight: isMdScreen ? '7rem' : undefined
                        }
                      }
                    }}>
                              {backendCurrencies.map((currency, i) => <MenuItem key={`currency-${i}`} value={currency.currency}>
                                  {currency.symbol}
                                </MenuItem>)}
                            </CustomSelect>
                          </Box>
                        </Stack> : null}
                    </Box> : null}
                </Box>

                <Divider sx={{
              mt: '1em',
              mb: {
                xs: '0.5em',
                md: '1em'
              }
            }} />
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="center" gap={2}>
                <Button color="success" size="large" disabled={isSubmitting || isSuccessSubmit} onClick={handleSubmit}>
                  {submitLabel}
                </Button>
                <Button color="error" size="large" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </Box>
            </Stack>
          </Box>}
      </BaseModal>
    </Modal>;
};
import Link from 'next/link';
import type { FC } from 'react';
interface SlugProps {
  prefix?: string;
  slug: string;
}
const Slug: FC<SlugProps> = ({
  prefix = '',
  slug
}) => {
  return <span data-sentry-component="Slug" data-sentry-source-file="Mention.tsx">
      <strong>
        {prefix}
        {slug}
      </strong>
    </span>;
};
type MentionProps = {
  mentions?: any[];
  title?: string;
};
const Mention: FC<MentionProps> = ({
  mentions,
  title
}) => {
  const handle = title?.slice(1);
  if (!handle) {
    return null;
  }
  const fullHandles = mentions?.map(mention => mention.snapshotHandleMentioned.fullHandle);
  if (!fullHandles?.includes(handle)) {
    return title;
  }
  const canShowUserPreview = (handle: string) => {
    const foundMention = mentions?.find(mention => mention.snapshotHandleMentioned.fullHandle === handle);
    return Boolean(foundMention?.snapshotHandleMentioned.linkedTo?.nftTokenId);
  };
  const getLocalNameFromFullHandle = (handle: string) => {
    const foundMention = mentions?.find(mention => mention.snapshotHandleMentioned.fullHandle === handle);
    return foundMention?.snapshotHandleMentioned.localName;
  };
  return canShowUserPreview(handle) ? <Link href={`/profile/handle/${getLocalNameFromFullHandle(handle)}`} onClick={event => {
    event.stopPropagation();
  }} style={{
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer'
  }}>
      <Slug prefix="@" slug={getLocalNameFromFullHandle(handle)} />
    </Link> : <Slug prefix="@" slug={getLocalNameFromFullHandle(handle)} />;
};
export default Mention;
import React, { useState } from 'react';
import { IconButton, Box, Badge, Menu, MenuItem, Avatar, Typography, Button, Chip } from '@mui/material';
import * as dropdownData from './data';
import Scrollbar from '../../../../components/custom-scroll/Scrollbar';
import { IconBellRinging } from '@tabler/icons-react';
import { Stack } from '@mui/system';
import Link from 'next/link';
const Notifications = () => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  return <Box data-sentry-element="Box" data-sentry-component="Notifications" data-sentry-source-file="Notification.tsx">
      <IconButton size="large" aria-label="show 11 new notifications" color="inherit" aria-controls="msgs-menu" aria-haspopup="true" sx={{
      color: anchorEl2 ? 'primary.main' : 'text.secondary'
    }} onClick={handleClick2} data-sentry-element="IconButton" data-sentry-source-file="Notification.tsx">
        <Badge variant="dot" color="primary" data-sentry-element="Badge" data-sentry-source-file="Notification.tsx">
          <IconBellRinging size="21" stroke="1.5" data-sentry-element="IconBellRinging" data-sentry-source-file="Notification.tsx" />
        </Badge>
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}
      <Menu id="msgs-menu" anchorEl={anchorEl2} keepMounted open={Boolean(anchorEl2)} onClose={handleClose2} anchorOrigin={{
      horizontal: 'right',
      vertical: 'bottom'
    }} transformOrigin={{
      horizontal: 'right',
      vertical: 'top'
    }} sx={{
      '& .MuiMenu-paper': {
        width: '360px'
      }
    }} data-sentry-element="Menu" data-sentry-source-file="Notification.tsx">
        <Stack direction="row" py={2} px={4} justifyContent="space-between" alignItems="center" data-sentry-element="Stack" data-sentry-source-file="Notification.tsx">
          <Typography variant="h6" data-sentry-element="Typography" data-sentry-source-file="Notification.tsx">Notifications</Typography>
          <Chip label="5 new" color="primary" size="small" data-sentry-element="Chip" data-sentry-source-file="Notification.tsx" />
        </Stack>
        <Scrollbar sx={{
        height: '385px'
      }} data-sentry-element="Scrollbar" data-sentry-source-file="Notification.tsx">
          {dropdownData.notifications.map((notification, index) => <Box key={index}>
              <MenuItem sx={{
            py: 2,
            px: 4
          }}>
                <Stack direction="row" spacing={2}>
                  <Avatar src={notification.avatar} alt={notification.avatar} sx={{
                width: 48,
                height: 48
              }} />
                  <Box>
                    <Typography variant="subtitle2" color="textPrimary" fontWeight={600} noWrap sx={{
                  width: '240px'
                }}>
                      {notification.title}
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2" sx={{
                  width: '240px'
                }} noWrap>
                      {notification.subtitle}
                    </Typography>
                  </Box>
                </Stack>
              </MenuItem>
            </Box>)}
        </Scrollbar>
        <Box p={3} pb={1} data-sentry-element="Box" data-sentry-source-file="Notification.tsx">
          <Button href="/apps/email" variant="outlined" component={Link} color="primary" fullWidth data-sentry-element="Button" data-sentry-source-file="Notification.tsx">
            See all Notifications
          </Button>
        </Box>
      </Menu>
    </Box>;
};
export default Notifications;
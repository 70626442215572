import { Avatar, Badge, Tooltip, useTheme } from '@mui/material';
const farcasterIcon = '/images/icons/farcaster.jpg';
const lensIcon = '/images/icons/lens.png';
type Props = {
  protocol: 'farcaster' | 'lens';
  avatarSrc: string;
  avatarSize?: number;
  iconSize?: number;
  showBorder?: boolean;
  hideBadge?: boolean;
  showTooltip?: boolean;
};
export const AvatarWrapper = ({
  protocol,
  avatarSrc,
  iconSize = 40,
  avatarSize = 100,
  showBorder = true,
  hideBadge = false,
  showTooltip = false
}: Props) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isFarcasterUser = protocol === 'farcaster';
  return <Badge invisible={hideBadge} badgeContent={showTooltip ? <Tooltip title={isFarcasterUser ? 'Farcaster profile' : 'Lens profile'}>
            <Avatar src={isFarcasterUser ? farcasterIcon : lensIcon} sx={{
      width: iconSize,
      height: iconSize
    }} style={{
      filter: !isFarcasterUser && isDarkMode ? 'invert(1)' : 'invert(0)'
    }} />
          </Tooltip> : <Avatar src={isFarcasterUser ? farcasterIcon : lensIcon} sx={{
    width: iconSize,
    height: iconSize
  }} style={{
    filter: !isFarcasterUser && isDarkMode ? 'invert(1)' : 'invert(0)'
  }} />} overlap="circular" anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'right'
  }} data-sentry-element="Badge" data-sentry-component="AvatarWrapper" data-sentry-source-file="AvatarWrapper.tsx">
      <Avatar src={avatarSrc} alt="profileImage" sx={{
      borderRadius: '50%',
      width: avatarSize,
      height: avatarSize,
      border: showBorder ? '4px solid #fff' : 'none'
    }} data-sentry-element="Avatar" data-sentry-source-file="AvatarWrapper.tsx" />
    </Badge>;
};
// ** Mui & React
import Link from 'next/link';
import { Avatar, Box, Button, CircularProgress, Popover, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';

// ** Hooks
import { useLens } from '@/context/lens';
import { useSession } from '@/context/session';

// ** Utils
import { getAvatarUrl } from '@/utils';
import { ProfileFragment } from '@lens-protocol/client';
import { ProfileItem } from '@/types/custom';
import { Protocol } from '../people/PeopleComponent';
import { TruncateText } from './TruncateText';
interface ProfileHoverProps {
  user?: ProfileFragment;
  formattedUser?: ProfileItem;
  protocol?: Protocol;
  children: ReactNode;
}
export default function ProfileHover({
  user,
  formattedUser,
  protocol,
  children
}: ProfileHoverProps) {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    checkIsFollowedByUser,
    follow,
    unfollow
  } = useLens();
  const {
    isFarcasterUser
  } = useSession();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [awaitFollowing, setAwaitFollowing] = useState(false);
  const profileId = user?.id || formattedUser?.id;

  /*************************************************
   *                  Functions                    *
   *************************************************/

  useEffect(() => {
    if (isPopoverOpen && (user?.id || formattedUser?.id) && !isFarcasterUser) {
      setAwaitFollowing(true);
      checkFollowStatus();
    }
  }, [isPopoverOpen]);
  const checkFollowStatus = async () => {
    try {
      if (!profileId) return;
      const res = await checkIsFollowedByUser(profileId);
      if (res?.[0]?.status?.value) {
        setIsFollow(true);
      } else {
        setIsFollow(false);
      }
      setAwaitFollowing(false);
    } catch (error) {
      console.error('Error checking follow status:', error);
    }
  };
  const handleFollow = async () => {
    setAwaitFollowing(true);
    if (!profileId) return;
    if (isFollow) {
      await unfollow(profileId);
      checkFollowStatus();
    } else {
      await follow(profileId);
      checkFollowStatus();
    }
    setAwaitFollowing(false);
  };
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsPopoverOpen(true);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false);
  };

  /*************************************************
   *                    Render                     *
   *************************************************/

  const FollowButton = () => {
    if (protocol === Protocol.FARCASTER && !isFarcasterUser || protocol === Protocol.LENS && isFarcasterUser) {
      return null;
    }
    if (awaitFollowing) {
      return <Button variant="contained" disabled size="small" sx={{
        mt: 1
      }}>
          <CircularProgress size={20} />
        </Button>;
    }
    if (!isFollow) {
      return <Button variant="contained" size="small" sx={{
        mt: 1
      }} onClick={handleFollow}>
          Follow
        </Button>;
    }
    if (isFollow) {
      return <Button variant="contained" size="small" color="error" sx={{
        mt: 1
      }} onClick={handleFollow}>
          Unfollow
        </Button>;
    } else {
      return <Button variant="contained" disabled size="small" sx={{
        mt: 1
      }}>
          <CircularProgress />
        </Button>;
    }
  };
  const profileName = useMemo(() => {
    if (user?.metadata?.displayName) {
      return user.metadata.displayName;
    }
    if (formattedUser?.metadata?.displayName) {
      return formattedUser.metadata.displayName;
    }
    if (formattedUser?.handle?.localName) {
      return `@${formattedUser.handle.localName}`;
    }
    if (user?.handle?.suggestedFormatted?.localName) {
      return user.handle.suggestedFormatted.localName;
    }
    return '';
  }, [user, formattedUser]);
  return <Box aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} data-sentry-element="Box" data-sentry-component="ProfileHover" data-sentry-source-file="ProfileHover.tsx">
      {children}

      <Popover id="mouse-over-popover" sx={{
      pointerEvents: 'none'
    }} open={isPopoverOpen} anchorEl={anchorEl} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }} PaperProps={{
      sx: {
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 400,
        pointerEvents: 'auto'
      }
    }} onMouseEnter={() => setIsPopoverOpen(true)} onMouseLeave={handlePopoverClose} disableRestoreFocus disableScrollLock data-sentry-element="Popover" data-sentry-source-file="ProfileHover.tsx">
        <Stack spacing={{
        xs: 2,
        md: 1
      }} padding="0.2em" display="flex" width="100%" data-sentry-element="Stack" data-sentry-source-file="ProfileHover.tsx">
          <Link style={{
          textDecoration: 'none',
          color: 'inherit'
        }} passHref href={protocol === Protocol.FARCASTER ? `/fid/${profileId}` : `/profile/${profileId}`} data-sentry-element="Link" data-sentry-source-file="ProfileHover.tsx">
            <Stack justifyContent="left" sx={{
            maxWidth: '14em',
            '&:hover': {
              cursor: 'pointer',
              '& .avatar': {
                filter: 'brightness(0.7)',
                transition: '0.2s'
              },
              '& .name': {
                color: theme.palette.primary.main,
                transition: '0.2s'
              },
              '& .handle': {
                color: theme.palette.grey[400],
                transition: '0.2s'
              }
            }
          }} data-sentry-element="Stack" data-sentry-source-file="ProfileHover.tsx">
              <Avatar className="avatar" src={user ? getAvatarUrl(user) : getAvatarUrl(formattedUser)} sx={{
              width: 60,
              height: 60,
              mb: 1
            }} alt={user?.metadata?.displayName ?? formattedUser?.handle?.localName ?? ''} data-sentry-element="Avatar" data-sentry-source-file="ProfileHover.tsx" />
              <Typography variant={isSmScreen ? 'h6' : 'h5'} className="name" data-sentry-element="Typography" data-sentry-source-file="ProfileHover.tsx">
                {profileName}
              </Typography>
            </Stack>
          </Link>
          <TruncateText text={user?.metadata?.bio ?? formattedUser?.metadata?.bio ?? ''} cleanNewLines textLength={500} lines={4} typographyProps={{
          color: 'textSecondary',
          variant: isSmScreen ? 'body2' : 'subtitle2',
          align: 'left'
        }} data-sentry-element="TruncateText" data-sentry-source-file="ProfileHover.tsx" />
          {protocol === Protocol.LENS && <FollowButton />}
        </Stack>
      </Popover>
    </Box>;
}
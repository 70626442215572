// ** React & MUI
import { useEffect, useMemo, useState } from 'react';
import { Modal, Button, Stack, CircularProgress, Box, Typography, Divider } from '@mui/material';

// ** Hooks
import { useModalsActions, useModalsState } from '@/context/modals';
import { useSession } from '@/context/session';
import { useZora } from '@/context/zora';
import { ConnectedWallet, useWallets } from '@privy-io/react-auth';
import { usePublicClient, useWaitForTransactionReceipt } from 'wagmi';
import { useEthersSigner } from '@/hooks/useEthersAdapters';
import { useERC20 } from '@/hooks/useERC20';

// ** Components
import BaseModal from './base';
import { IconCheck, IconX } from '@tabler/icons-react';

// ** Utils & Types
import { ZORA_ID } from '@/utils/constants/networks';
import { Erc20Approval, MintCosts, OnchainSalesStrategies } from '@zoralabs/protocol-sdk/dist/mint/types';
import { erc20Abi, formatEther } from 'viem';
import { StatusEnum as Status } from '@/types/custom';
import { SimulateContractParametersWithAccount } from '@zoralabs/protocol-sdk/dist/types';
import { Contract } from 'ethers';

/**
 * Payload for the Zora Collect modal
 */
export interface ZoraCollectPayload {
  mintType: '721' | '1155';
  tokenAddress: `0x${string}`;
  tokenId: string;
  tokenName: string;
  salesConfig?: OnchainSalesStrategies;
  referral?: `0x${string}`;
  onSuccess: () => void;
}

/////////////////////////////////////
export const ZoraCollectModal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [collectCosts, setCollectCosts] = useState<MintCosts>();
  const [collectParameters, setCollectParameters] = useState<SimulateContractParametersWithAccount>();
  const [erc20Approval, setErc20Approval] = useState<Erc20Approval>();
  const [insufficientBalance, setInsufficientBalance] = useState<boolean>(false);
  const [parsedAmount, setParsedAmount] = useState<number>();
  const [collectAddress, setCollectAddress] = useState<`0x${string}`>();
  const [collectId, setCollectId] = useState<string>();
  const [collectName, setCollectName] = useState<string>('');
  const [collectConfig, setCollectConfig] = useState<OnchainSalesStrategies>();
  const [erc20Currency, setErc20Currency] = useState<string>();
  const [erc20Amount, setErc20Amount] = useState<number>();
  const [approveStatus, setApproveStatus] = useState<Status>();
  const [mintStatus, setMintStatus] = useState<Status>(Status.idle);
  const [wholeStatus, setWholeStatus] = useState<Status>(Status.idle);
  const [txHash, setTxHash] = useState<`0x${string}`>();
  const {
    isSuccess: isWaitSuccess,
    isError: isWaitError
  } = useWaitForTransactionReceipt({
    hash: txHash
  });
  const {
    close
  } = useModalsActions();
  const {
    zoraCollect: collectModal
  } = useModalsState();
  const {
    isOpen,
    data: modalData
  } = collectModal;
  const {
    mint,
    client: zoraClient
  } = useZora();
  const {
    user
  } = useSession();
  const {
    wallets
  } = useWallets();
  const publicClient = usePublicClient();
  const signer = useEthersSigner();
  const {
    symbol,
    decimals,
    balance: userBalance
  } = useERC20(erc20Currency, user.address);
  useEffect(() => {
    if (!isOpen || !modalData) return;
    const {
      mintType,
      tokenAddress,
      tokenId,
      tokenName,
      salesConfig,
      referral
    } = modalData;
    fetchCollectData(mintType, tokenAddress, tokenId, tokenName, salesConfig, referral);
    return cleanState;
  }, [modalData, isOpen]);
  useEffect(() => {
    const isApproveSuccess = approveStatus === Status.success;
    const isMintWaiting = mintStatus === Status.idle;
    const isMintSuccess = mintStatus === Status.success;
    const isProcessLoading = wholeStatus === Status.loading;
    if (isApproveSuccess && isMintWaiting) {
      handleMint();
    }
    if (isProcessLoading && isMintSuccess && isWaitSuccess) {
      handleSucess();
    }
  }, [approveStatus, mintStatus, isWaitSuccess, wholeStatus]);

  /*************************************************
   *                  Functions                    *
   *************************************************/

  const fetchCollectData = async (mintType: '721' | '1155', tokenAddress: `0x${string}`, tokenId: string, tokenName: string, salesConfig?: OnchainSalesStrategies, referral?: `0x${string}`) => {
    try {
      if (!zoraClient || !user.address) return;
      setIsLoading(true);
      setCollectAddress(tokenAddress);
      setCollectId(tokenId);
      setCollectName(tokenName);
      setCollectConfig(salesConfig);
      const mintParams = {
        mintType,
        tokenContract: tokenAddress,
        mintRecipient: user.address,
        quantityToMint: 1,
        minterAccount: user.address,
        tokenId,
        mintReferral: referral
      };
      const {
        parameters,
        costs,
        erc20Approval
      } = await zoraClient.mint(mintParams);
      const balance = await publicClient?.getBalance({
        address: user.address
      });
      if (salesConfig?.saleType === 'erc20') {
        const tokenPrice = Number(salesConfig.pricePerToken);
        setErc20Amount(tokenPrice);
        setErc20Currency(salesConfig.currency);
      }
      if (Number(balance) < Number(costs.totalCostEth)) {
        setInsufficientBalance(true);
      }
      const parsedCostAmount = formatEther(costs.totalCostEth);
      setCollectCosts(costs);
      setCollectParameters(parameters);
      setErc20Approval(erc20Approval);
      setParsedAmount(Number(parsedCostAmount));
    } catch (error) {
      console.log('Error while fetching collect data: ', error);
    } finally {
      setIsLoading(false);
    }
  };
  const cleanState = () => {
    setCollectCosts(undefined);
    setErc20Approval(undefined);
    setParsedAmount(undefined);
    setInsufficientBalance(false);
    setMintStatus(Status.idle);
    setApproveStatus(undefined);
    setWholeStatus(Status.idle);
    setTxHash(undefined);
    setCollectName('');
    setErc20Amount(undefined);
    setErc20Currency(undefined);
  };
  const handleCloseModal = () => {
    if (mintStatus === Status.loading || approveStatus === Status.loading) return;
    close('zoraCollect');
  };
  const handleSucess = () => {
    setWholeStatus(Status.success);
    if (collectModal.data?.onSuccess) collectModal.data.onSuccess();
    setTimeout(() => {
      handleCloseModal();
    }, 1000);
  };
  const checkZoraNetwork = async (wallet: ConnectedWallet) => {
    if (wallet.chainId === ZORA_ID.toString()) return true;else {
      try {
        await wallet.switchChain(ZORA_ID);
        return true;
      } catch (error) {
        console.log('Error switching to zora network', error);
        return false;
      }
    }
  };
  const checkAllowance = async (approvalConfig: Erc20Approval) => {
    try {
      const erc20Contract = new Contract(approvalConfig.erc20, erc20Abi, signer);
      const allowance = await erc20Contract.allowance(user.address, approvalConfig.approveTo);
      return Number(allowance) >= Number(approvalConfig.quantity);
    } catch (error) {
      console.log('Error checking allowance', error);
      return false;
    }
  };
  const handleApprove = async (approvalConfig: Erc20Approval) => {
    try {
      setApproveStatus(Status.loading);
      const erc20Contract = new Contract(approvalConfig.erc20, erc20Abi, signer);
      const txFee = await erc20Contract.approve(approvalConfig.approveTo, approvalConfig.quantity);
      await txFee.wait();
      setApproveStatus(Status.success);
    } catch (err) {
      console.log('Error approving', err);
      setApproveStatus(Status.error);
    }
  };
  const handleMint = async () => {
    if (!user.address) return;
    try {
      setMintStatus(Status.loading);
      if (!collectParameters || !collectCosts) throw new Error('Collect parameters not found');
      const tx = await mint(user.address, collectParameters, collectCosts);
      setTxHash(tx);
      setMintStatus(Status.success);
    } catch (err) {
      console.log('Error minting', err);
      setMintStatus(Status.error);
    }
  };
  const handleSubmit = async (collectTokenAddress?: `0x${string}`, collectTokenId?: string) => {
    try {
      setWholeStatus(Status.loading);
      if (!user.address) throw new Error('User address not found');
      if (!collectTokenAddress || !collectTokenId) throw new Error('Contract address not found');
      const wallet = wallets.length > 0 ? wallets[0] : null;
      if (!wallet) throw new Error('Privy wallet not found');
      const isConnected = await checkZoraNetwork(wallet);
      if (!isConnected) return;
      if (erc20Approval) {
        const hasEnoughAllowance = await checkAllowance(erc20Approval);
        if (!hasEnoughAllowance) {
          setApproveStatus(Status.loading);
          await handleApprove(erc20Approval);
        } else {
          setApproveStatus(undefined);
          await handleMint();
        }
      } else {
        setApproveStatus(undefined);
        await handleMint();
      }
    } catch (err) {
      console.log('Error collecting publication', err);
      setWholeStatus(Status.error);
    }
  };
  const submitLabel = useMemo(() => {
    if (wholeStatus === Status.success) return <IconCheck size={24} />;
    if (wholeStatus === Status.error || approveStatus === Status.error || mintStatus === Status.error) return <IconX size={20} />;
    if (wholeStatus === Status.loading) return <CircularProgress size={24} />;
    return 'Collect';
  }, [wholeStatus, approveStatus, mintStatus, mintStatus]);
  const erc20BalanceNeeded = erc20Amount && decimals ? Number(erc20Amount) / 10 ** Number(decimals) : null;
  const insufficientErc20Balance = erc20BalanceNeeded ? Number(userBalance) < Number(erc20BalanceNeeded) : false;
  const isSubmitDisabled = approveStatus === Status.loading || approveStatus === Status.error || mintStatus === Status.loading || mintStatus === Status.error || mintStatus === Status.success || wholeStatus === Status.loading || wholeStatus === Status.error || insufficientBalance || insufficientErc20Balance || !collectConfig;
  return <Modal open={collectModal.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-component="ZoraCollectModal" data-sentry-source-file="zora-collect.tsx">
      <BaseModal sx={{
      justifyContent: 'center'
    }} data-sentry-element="BaseModal" data-sentry-source-file="zora-collect.tsx">
        {isLoading ? <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
            <CircularProgress />
          </Box> : <Stack spacing={4}>
            <Stack gap={0.4}>
              <Typography variant="h5" textAlign="center">
                Collect details
              </Typography>
              <Divider sx={{
            mt: '0.5em'
          }} />
              <Typography variant="subtitle2" textAlign="center" sx={{
            fontWeight: 'bold'
          }}>
                {`${collectName}`}
              </Typography>
              {collectConfig?.saleType === 'allowlist' && <Typography variant="subtitle2">
                  Only allowlisted users can collect this.
                </Typography>}
              {collectConfig?.saleType === 'fixedPrice' && <Typography variant="subtitle2">
                  The price is fixed at {`${parsedAmount} ETH`}.
                </Typography>}
              {collectConfig?.saleEnd && collectConfig.saleEnd !== '0' && <>
                  {Number(collectConfig.saleEnd) < Date.now() ? <Typography variant="subtitle2">
                      The sale is timed and will end at{' '}
                      {`${new Date(Number(collectConfig.saleEnd) * 1000).toLocaleString()}`}
                      .
                    </Typography> : <Typography variant="subtitle2">Sale has ended.</Typography>}
                </>}
              {collectConfig?.saleType === 'erc20' && <Typography variant="subtitle2">
                  You need to approve the ERC20 token before collecting.
                </Typography>}
              {collectCosts && parsedAmount ? <>
                  <Stack direction="row" gap={0.5} mt={1}>
                    <Typography variant="subtitle2" sx={{
                fontWeight: 'bold'
              }}>
                      Collect total costs:
                    </Typography>
                    <Typography variant="subtitle2">{`${parsedAmount} ETH`}</Typography>
                  </Stack>
                </> : null}
              {erc20BalanceNeeded && <Stack direction="row" gap={0.5} mt={1}>
                  <Typography variant="subtitle2" sx={{
              fontWeight: 'bold'
            }}>
                    ERC20 balance needed:
                  </Typography>
                  <Typography variant="subtitle2">{`${erc20BalanceNeeded} ${symbol}`}</Typography>
                </Stack>}
              {insufficientBalance || insufficientErc20Balance ? <Stack direction="row" gap={0.5}>
                  <Typography variant="subtitle2" color="error" sx={{
              fontWeight: 'bold'
            }}>
                    Insufficient balance
                  </Typography>
                </Stack> : null}
            </Stack>
            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
              <Button color="success" variant="contained" size="large" disabled={isSubmitDisabled} onClick={() => handleSubmit(collectAddress, collectId)}>
                {submitLabel}
              </Button>
              <Button color="error" size="large" onClick={() => close('zoraCollect')}>
                Cancel
              </Button>
            </Stack>
          </Stack>}
      </BaseModal>
    </Modal>;
};
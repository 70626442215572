// ** React Imports
import { useEffect, useMemo } from 'react';

// ** MUI Imports
import { Box, Divider, Modal, Stack, Typography } from '@mui/material';
import { IconX } from '@tabler/icons-react';

// ** Hooks Imports
import { useSession } from '@/context/session';
import { useModalsActions, useModalsState } from '@/context/modals';

// ** Components
import { LoginWallet } from '../ctaButtons/wallet/LoginWallet';
import { SignInBtn } from '../ctaButtons/wallet/SignInWallet';
import BaseModal from './base';
export enum NotConnectedCase {
  NotConnected = 'not-connected',
  PendingLogIn = 'pending-login',
}
export type NotConnectedPayload = {
  modalCase: NotConnectedCase;
};
export const NotConnectedModal = () => {
  const {
    notLoggedIn,
    connectedNotLogged
  } = useSession();
  const {
    notConnected
  } = useModalsState();
  const {
    close
  } = useModalsActions();
  const handleCloseModal = () => close('notConnected');
  const completed = useMemo(() => {
    if (!notConnected.data) return false;
    const {
      modalCase
    } = notConnected.data;
    const notLoggedCompleted = modalCase === NotConnectedCase.NotConnected && !notLoggedIn;
    const pendingCompleted = modalCase === NotConnectedCase.PendingLogIn && !connectedNotLogged;
    return notLoggedCompleted || pendingCompleted;
  }, [notConnected, notLoggedIn, connectedNotLogged]);
  useEffect(() => {
    if (completed) handleCloseModal();
  }, [completed]);
  if (!notConnected.data) return null;
  const {
    modalCase
  } = notConnected.data;
  const isPendingLogin = modalCase === NotConnectedCase.PendingLogIn;
  return <>
   <Modal open={notConnected.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-source-file="not-connected.tsx">
    <BaseModal action={<IconX onClick={handleCloseModal} style={{
        cursor: 'pointer'
      }} />} sx={{
        minHeight: '10rem',
        minWidth: '15rem'
      }} data-sentry-element="BaseModal" data-sentry-source-file="not-connected.tsx">
     <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} gap={1} data-sentry-element="Stack" data-sentry-source-file="not-connected.tsx">
      <Typography variant='h5' textAlign={'center'} width={'100%'} data-sentry-element="Typography" data-sentry-source-file="not-connected.tsx">
       {isPendingLogin ? 'Pending login' : 'Not connected'}
      </Typography>
      <Divider data-sentry-element="Divider" data-sentry-source-file="not-connected.tsx" />
      <Typography variant='body1' textAlign={'center'} data-sentry-element="Typography" data-sentry-source-file="not-connected.tsx">
       {isPendingLogin ? 'You should complete the login process to continue.' : 'You should connect your wallet to get a better experience.'}
      </Typography>
      <Divider data-sentry-element="Divider" data-sentry-source-file="not-connected.tsx" />
      <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            mt: 1
          }} data-sentry-element="Box" data-sentry-source-file="not-connected.tsx">
       {isPendingLogin ? <SignInBtn variant='contained' color={undefined} size='large' label='Complete login' beforeOpen={() => close('notConnected')} /> : <LoginWallet variant='contained' size='large' label='Connect' beforeOpen={() => close('notConnected')} />}
      </Box>
     </Stack>
    </BaseModal>
   </Modal>
  </>;
};
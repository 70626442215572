import * as Sentry from '@sentry/nextjs'
import { ExclusiveEventHintOrCaptureContext } from '@sentry/core/build/types/utils/prepareEvent'
import { isProductionMode } from '.'

export class MonitorError extends Error {
  constructor(error: any, hints?: ExclusiveEventHintOrCaptureContext) {
    if (isProductionMode) {
      Sentry.captureException(error, hints)
    }
    super(error)
  }
}

export class MonitorMessage {
  constructor(message: string, level: 'info' | 'error' | 'debug' | 'warning') {
    if (isProductionMode) {
      Sentry.captureMessage(message, level)
    }
  }
}

import React, { useState } from 'react';
import { sum } from 'lodash';
import { IconShoppingCart, IconX } from '@tabler/icons-react';
import { Box, Typography, Badge, Drawer, IconButton, Button, Stack } from '@mui/material';
import { useSelector } from '../../../../store/Store';
import Link from 'next/link';
import CartItems from './CartItem';
import { AppState } from '../../../../store/Store';
const Cart = () => {
  // Get Products
  const Cartproduct = useSelector((state: AppState) => state.ecommerceReducer.cart);
  const bcount = Cartproduct.length > 0 ? Cartproduct.length : '0';
  const checkout = useSelector((state: AppState) => state.ecommerceReducer.cart);
  const total = sum(checkout.map((product: any) => product.price * product.qty));
  const [showDrawer, setShowDrawer] = useState(false);
  const handleDrawerClose = () => {
    setShowDrawer(false);
  };
  const cartContent = <Box>
      {/* ------------------------------------------- */}
      {/* Cart Content */}
      {/* ------------------------------------------- */}
      <Box>
        <CartItems />
      </Box>
    </Box>;
  return <Box data-sentry-element="Box" data-sentry-component="Cart" data-sentry-source-file="Cart.tsx">
      <IconButton size="large" color="inherit" onClick={() => setShowDrawer(true)} sx={{
      color: 'text.secondary',
      ...(showDrawer && {
        color: 'primary.main'
      })
    }} data-sentry-element="IconButton" data-sentry-source-file="Cart.tsx">
        <Badge color="error" badgeContent={bcount} data-sentry-element="Badge" data-sentry-source-file="Cart.tsx">
          <IconShoppingCart size="21" stroke="1.5" data-sentry-element="IconShoppingCart" data-sentry-source-file="Cart.tsx" />
        </Badge>
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Cart Sidebar */}
      {/* ------------------------------------------- */}
      <Drawer anchor="right" open={showDrawer} onClose={() => setShowDrawer(false)} PaperProps={{
      sx: {
        maxWidth: '500px'
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="Cart.tsx">
        <Box display="flex" alignItems="center" p={3} pb={0} justifyContent="space-between" data-sentry-element="Box" data-sentry-source-file="Cart.tsx">
          <Typography variant="h5" fontWeight={600} data-sentry-element="Typography" data-sentry-source-file="Cart.tsx">
            Shopping Cart
          </Typography>
          <Box data-sentry-element="Box" data-sentry-source-file="Cart.tsx">
            <IconButton color="inherit" sx={{
            color: theme => theme.palette.grey.A200
          }} onClick={handleDrawerClose} data-sentry-element="IconButton" data-sentry-source-file="Cart.tsx">
              <IconX size="1rem" data-sentry-element="IconX" data-sentry-source-file="Cart.tsx" />
            </IconButton>
          </Box>
        </Box>

        {/* component */}
        {cartContent}
        {/* ------------------------------------------- */}
        {/* Checkout  */}
        {/* ------------------------------------------- */}
        <Box px={3} mt={2} data-sentry-element="Box" data-sentry-source-file="Cart.tsx">
          {Cartproduct.length > 0 ? <>
              <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography variant="subtitle2" fontWeight={400}>
                  Total
                </Typography>
                <Typography variant="subtitle2" fontWeight={600}>
                  ${total}
                </Typography>
              </Stack>
              <Button fullWidth component={Link} href="/apps/ecommerce/eco-checkout" variant="contained" color="primary">
                Checkout
              </Button>
            </> : ''}
        </Box>
      </Drawer>
    </Box>;
};
export default Cart;
// ** React
import { createContext, ReactNode, useContext } from 'react';

// ** Components
import Arweave from 'arweave';
import { wagmiConfig } from '@/components/config/wallet.config';
import { simulateContract, readContract } from '@wagmi/core';
import { usePublicClient, useWriteContract } from 'wagmi';

// ** Utils
import baseAbi from '../utils/abis/baseAbi.json';
import getTokenPrice from '../utils/abis/getTokenPrice.json';
import { encodeMinterArguments } from '@/utils/helpers';
import { BASE_MINTER } from '@/utils/constants/literals';
import axios from 'axios';
interface PodsValue {
  /**
   * Arweave client
   * @returns {Arweave}
   */
  client: Arweave;

  /**
   * Fetch Pods metadata by podsId
   * @returns {Promise<ResponseWithData | null>} podsId
   */
  fetchPodMetadata: (podId: string) => Promise<any>;

  /**
   * Fetch mint price of a given contractAddress
   * @returns {Promise<bigint | null>} bigint
   */
  fetchMintPrice: (podContract: `0x${string}`) => Promise<any>;
  /**
   * Mint a pod by given contractAddress
   * @returns {Promise<ResponseWithData | null>} contractAddress
   */
  mint: (podContract: `0x${string}`, tokenId: string, userAddress: `0x${string}`, mintReferral?: `0x${string}`) => Promise<`0x${string}` | undefined>;
}
const PodsContext = createContext<PodsValue>({} as PodsValue);
export function PodsProvider({
  children
}: {
  children: ReactNode;
}) {
  const {
    writeContractAsync
  } = useWriteContract();
  const publicClient = usePublicClient();
  const client = Arweave.init({
    host: 'arweave.net',
    port: 443,
    protocol: 'https'
  });
  const fetchPodMetadata = async (podId: string) => {
    try {
      const response = await axios.get('/api/proxy/fetch-metadata', {
        params: {
          podId: podId
        },
        timeout: 5000,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('Error while fetching pod metadata: ', error.response?.data || error.message);
      } else {
        console.log('Error while fetching pod metadata: ', error);
      }
      throw error;
    }
  };
  const fetchMintPrice = async (podContract: `0x${string}`) => {
    try {
      const tokenPrice = await readContract(wagmiConfig, {
        address: podContract as `0x${string}`,
        abi: getTokenPrice,
        functionName: 'computeTotalReward',
        args: [1]
      });
      return tokenPrice;
    } catch (error) {
      console.log('Error while ferching pod collect price: ', error);
    }
  };
  const mint = async (podContract: `0x${string}`, tokenId: string, userAddress: `0x${string}`, mintReferral?: `0x${string}`) => {
    try {
      if (!publicClient) return;
      const minterArguments = encodeMinterArguments(userAddress);
      const minter = BASE_MINTER;
      const quantity = 1;
      const rewardsRecipients: `0x${string}`[] = [];
      if (mintReferral) {
        rewardsRecipients.push(mintReferral);
      }
      const tokenPrice = await readContract(wagmiConfig, {
        address: podContract as `0x${string}`,
        abi: getTokenPrice,
        functionName: 'computeTotalReward',
        args: [1]
      });
      const simulated = await simulateContract(wagmiConfig, {
        address: podContract as `0x${string}`,
        abi: baseAbi,
        functionName: 'mint',
        args: [BASE_MINTER, tokenId, 1, [], minterArguments],
        value: tokenPrice as bigint
      });
      const tx = await writeContractAsync({
        address: podContract,
        abi: baseAbi,
        functionName: 'mint',
        args: [minter, 1, quantity, rewardsRecipients, minterArguments],
        value: tokenPrice as bigint
      });
      return tx;
    } catch (error) {
      console.log('Error while minting pod: ', error);
    }
  };
  const value: PodsValue = {
    client,
    fetchPodMetadata,
    fetchMintPrice,
    mint
  };
  return <PodsContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="PodsProvider" data-sentry-source-file="pods.tsx">{children}</PodsContext.Provider>;
}

/**
 * Arweave context hook.
 * @return Arweave context value.
 */
export function usePods() {
  return useContext(PodsContext);
}
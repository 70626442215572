import React from "react";
import { useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";

// mui imports
import { ListItemIcon, styled, ListItemText, Box, ListItemButton } from "@mui/material";
import { useSelector } from "../../../../../store/Store";

// custom imports
import NavItem from "../NavItem/NavItem";

// plugins
import { IconChevronDown } from "@tabler/icons-react";
import { AppState } from "../../../../../store/Store";
type NavGroupProps = {
  [x: string]: any;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: any;
};
interface NavCollapseProps {
  menu: NavGroupProps;
  level: number;
  pathWithoutLastPart: any;
  pathDirect: any;
  hideMenu: any;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

// FC Component For Dropdown Menu
const NavCollapse = ({
  menu,
  level,
  pathWithoutLastPart,
  pathDirect,
  hideMenu,
  onClick
}: NavCollapseProps) => {
  const Icon = menu.icon;
  const theme = useTheme();
  const {
    pathname
  } = useRouter();
  const [open, setOpen] = React.useState(false);
  const customizer = useSelector((state: AppState) => state.customizer);
  const menuIcon = level > 1 ? <Icon stroke={1.5} size="1rem" /> : <Icon stroke={1.5} size="1.1rem" />;
  React.useEffect(() => {
    setOpen(false);
    menu.children.forEach((item: any) => {
      if (item.href === pathname) {
        setOpen(true);
      }
    });
  }, [pathname, menu.children]);
  const ListItemStyled = styled(ListItemButton)(() => ({
    width: "auto",
    padding: "5px 10px",
    position: "relative",
    flexGrow: "unset",
    gap: "10px",
    borderRadius: `${customizer.borderRadius}px`,
    whiteSpace: "nowrap",
    color: open || pathname.includes(menu.href) || level < 1 ? "white" : theme.palette.text.secondary,
    backgroundColor: open || pathname.includes(menu.href) ? theme.palette.primary.main : "",
    "&:hover": {
      backgroundColor: open || pathname.includes(menu.href) ? theme.palette.primary.main : theme.palette.primary.light
    },
    "&:hover > .SubNav": {
      display: "block"
    }
  }));
  const ListSubMenu = styled(Box)(() => ({
    display: "none",
    position: "absolute",
    top: level > 1 ? `0px` : "35px",
    left: level > 1 ? `${level + 228}px` : "0px",
    padding: "10px",
    width: "250px",
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[8],
    backgroundColor: theme.palette.background.paper
  }));
  const listItemProps: {
    component: string;
  } = {
    component: "li"
  };

  // If Menu has Children
  const submenus = menu.children?.map((item: any) => {
    if (item.children) {
      return <NavCollapse key={item.id} menu={item} level={level + 1} pathWithoutLastPart={pathWithoutLastPart} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
    } else {
      return <NavItem key={item.id} item={item} level={level + 1} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
    }
  });
  return <React.Fragment key={menu.id}>
      <ListItemStyled {...listItemProps} selected={pathWithoutLastPart === menu.href} className={open ? "selected" : ""} data-sentry-element="ListItemStyled" data-sentry-source-file="NavCollapse.tsx">
        <ListItemIcon sx={{
        minWidth: "auto",
        p: "3px 0",
        color: "inherit"
      }} data-sentry-element="ListItemIcon" data-sentry-source-file="NavCollapse.tsx">
          {menuIcon}
        </ListItemIcon>
        <ListItemText color="inherit" sx={{
        mr: "auto"
      }} data-sentry-element="ListItemText" data-sentry-source-file="NavCollapse.tsx">
          {menu.title}
        </ListItemText>
        <IconChevronDown size="1rem" data-sentry-element="IconChevronDown" data-sentry-source-file="NavCollapse.tsx" />
        <ListSubMenu component={"ul"} className="SubNav" data-sentry-element="ListSubMenu" data-sentry-source-file="NavCollapse.tsx">
          {submenus}
        </ListSubMenu>
      </ListItemStyled>
    </React.Fragment>;
};
export default NavCollapse;
const LightThemeColors = [{
  name: 'BLUE_THEME',
  palette: {
    primary: {
      main: '#5D87FF',
      light: '#ECF2FF',
      dark: '#4570EA',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#49BEFF',
      light: '#E8F7FF',
      dark: '#23afdb',
      contrastText: '#ffffff'
    }
  }
}, {
  name: 'AQUA_THEME',
  palette: {
    primary: {
      main: '#0074BA',
      light: '#EFF9FF',
      dark: '#006DAF',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#47D7BC',
      light: '#EDFBF7',
      dark: '#39C7AD',
      contrastText: '#ffffff'
    }
  }
}, {
  name: 'PURPLE_THEME',
  palette: {
    primary: {
      main: '#763EBD',
      light: '#F2ECF9',
      dark: '#6E35B7',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#95CFD5',
      light: '#EDF8FA',
      dark: '#8BC8CE',
      contrastText: '#ffffff'
    }
  }
}, {
  name: 'GREEN_THEME',
  palette: {
    primary: {
      main: '#0A7EA4',
      light: '#F4F9FB',
      dark: '#06769A',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#CCDA4E',
      light: '#FAFBEF',
      dark: '#C3D046',
      contrastText: '#ffffff'
    }
  }
}, {
  name: 'CYAN_THEME',
  palette: {
    primary: {
      main: '#01C0C8',
      light: '#EBF9FA',
      dark: '#00B9C0',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#FB9678',
      light: '#FFF5F2',
      dark: '#F48B6C',
      contrastText: '#ffffff'
    }
  }
}, {
  name: 'ORANGE_THEME',
  palette: {
    primary: {
      main: '#FA896B',
      light: '#FBF2EF',
      dark: '#F48162',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#0074BA',
      light: '#EFF9FF',
      dark: '#006FB1',
      contrastText: '#ffffff'
    }
  }
}];
export { LightThemeColors };
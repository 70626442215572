// ** React & MUI
import { useState } from 'react';
import { Modal, Button, Stack, TextField, CircularProgress } from '@mui/material';

// ** Components
import BaseModal from './base';

// ** Context
import { useModalsActions, useModalsState } from '@/context/modals';
import { useLens } from '@/context/lens';

/**
 * Payload for the Comment modal
 * @param publicationId - The publicationId where the comment will be created.
 */
export interface CommentPayload {
  publicationId: string;
  isMomokaPub: boolean;
}

/////////////////////////////////////
export const CommentModal = () => {
  const {
    close
  } = useModalsActions();
  const {
    comment: commentModal
  } = useModalsState();
  const {
    comment: commentOnLens
  } = useLens();
  const [comment, setComment] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);
  if (!commentModal.data) return null;
  const {
    publicationId,
    isMomokaPub
  } = commentModal.data;
  const handleCloseModal = () => {
    close('comment');
    setComment('');
  };
  const handleConfirmClick = async () => {
    setSubmitting(true);
    await handleSubmit();
  };
  const handleSubmit = async () => {
    try {
      await commentOnLens(publicationId, comment, isMomokaPub);
      handleCloseModal();
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };
  return <>
      <Modal open={commentModal.isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-source-file="comment.tsx">
        <BaseModal sx={{
        justifyContent: 'center'
      }} data-sentry-element="BaseModal" data-sentry-source-file="comment.tsx">
          <Stack spacing={2} data-sentry-element="Stack" data-sentry-source-file="comment.tsx">
            <TextField id="outlined-multiline-static" placeholder="Share your thoughts" value={comment} onChange={e => setComment(e.target.value)} multiline fullWidth rows={4} data-sentry-element="TextField" data-sentry-source-file="comment.tsx" />
            <Stack display="flex" flexDirection="row" justifyContent="center" gap={2} data-sentry-element="Stack" data-sentry-source-file="comment.tsx">
              <Button color="success" size="large" disabled={submitting} onClick={handleConfirmClick} data-sentry-element="Button" data-sentry-source-file="comment.tsx">
                {!submitting ? 'Post' : <CircularProgress />}
              </Button>
              <Button color="error" size="large" onClick={handleCloseModal} data-sentry-element="Button" data-sentry-source-file="comment.tsx">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      </Modal>
    </>;
};
import ExternalLink from './ExternalLink';
import Mention from './Mention';
type MarkupLinkProps = {
  mentions?: any[];
  title?: string;
};
const MarkupLink = ({
  mentions,
  title
}: MarkupLinkProps) => {
  if (!title) {
    return null;
  }
  if (title.startsWith('@')) {
    return <Mention mentions={mentions} title={title} />;
  }
  return <ExternalLink title={title} data-sentry-element="ExternalLink" data-sentry-component="MarkupLink" data-sentry-source-file="index.tsx" />;
};
export default MarkupLink;
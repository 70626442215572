import { Button } from '@mui/material';
import { useSwitchChain } from 'wagmi';
import { DEFAULT_NETWORK } from '@/utils/constants/networks';
type Props = {
  /**
   * The variant to use.
   * @default "text"
   */
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  /**
   * The color of the component.
   * It supports both default and custom theme colors, which can be added as shown in the
   * [palette customization guide](https://mui.com/material-ui/customization/palette/#adding-new-colors).
   * @default "primary"
   */
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning' | 'inherit' | undefined;
  /**
   * The size of the component.
   * `small` is equivalent to the dense button styling.
   * @default "medium"
   */
  size?: 'small' | 'medium' | 'large' | undefined;
  /**
   * The text to be displayed inside the button.
   * @default "Switch Network"
   */
  label?: string;
};
export const SwitchNetwork = ({
  variant = 'text',
  color = 'primary',
  size = 'medium',
  label = 'Switch Network'
}: Props) => {
  const {
    switchChain
  } = useSwitchChain();
  const handleSwitchNetwork = () => {
    switchChain?.({
      chainId: DEFAULT_NETWORK
    });
  };
  return <Button variant={variant} color={color} size={size} onClick={handleSwitchNetwork} data-sentry-element="Button" data-sentry-component="SwitchNetwork" data-sentry-source-file="SwitchNetwork.tsx">
   {label}
  </Button>;
};
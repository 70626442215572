import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSession } from './session';
type OnleyMyNetworkContextType = {
  isMyNetworkOnly: {
    pods: boolean;
    lens: boolean;
    zora: boolean;
  };
  toggleMyNetworkOnly: (section?: 'pods' | 'lens' | 'zora') => void;
};
const OnlyMyNetworkContext = createContext<OnleyMyNetworkContextType>({} as OnleyMyNetworkContextType);
export const OnlyMyNetworkProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [isMyNetworkOnly, setIsMyNetworkOnly] = useState({
    pods: false,
    lens: false,
    zora: false
  });
  const {
    notLoggedIn
  } = useSession();
  useEffect(() => {
    if (notLoggedIn) {
      setIsMyNetworkOnly({
        pods: false,
        lens: false,
        zora: false
      });
    }
  }, [notLoggedIn]);
  const toggleMyNetworkOnly = (section?: 'pods' | 'lens' | 'zora') => {
    if (section) {
      setIsMyNetworkOnly(prev => ({
        ...prev,
        [section]: !prev[section]
      }));
    } else {
      setIsMyNetworkOnly(prev => ({
        pods: !prev.pods,
        lens: !prev.lens,
        zora: !prev.zora
      }));
    }
  };
  return <OnlyMyNetworkContext.Provider value={{
    isMyNetworkOnly,
    toggleMyNetworkOnly
  }} data-sentry-element="unknown" data-sentry-component="OnlyMyNetworkProvider" data-sentry-source-file="onlyNextwork.tsx">
      {children}
    </OnlyMyNetworkContext.Provider>;
};
export const useOnlyMyNetwork = () => {
  return useContext(OnlyMyNetworkContext);
};
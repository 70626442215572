import { Typography, Link as MuiLink, TypographyProps, SxProps, useTheme } from '@mui/material';
import Link from 'next/link';
import { useRef, useState, useEffect } from 'react';
import Markup from './markup';
type TruncateTextProps = {
  text: string | null;
  postId?: string;
  textLength?: number;
  mentions?: any[];
  lines?: number;
  typographyProps?: TypographyProps;
  sx?: SxProps;
  cleanNewLines?: boolean;
  showReadMore?: boolean;
};
export const TruncateText: React.FC<TruncateTextProps> = ({
  text,
  postId,
  textLength = 250,
  mentions,
  lines = 5,
  typographyProps,
  sx,
  cleanNewLines = false,
  showReadMore = true
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const postDetailPath = postId ? `/content/${postId}` : '/';
  const cleanText = (text: string | null): string => {
    if (!text) return '';
    if (!cleanNewLines) return text;
    return text.replace(/(\r\n|\n|\r){2,}/g, '\n').replace(/\s+/g, ' ').trim().split('\n').filter(line => line.trim() !== '').join('\n');
  };
  useEffect(() => {
    if (!cleanNewLines) {
      setIsTruncated(false);
      return;
    }
    const checkTruncation = () => {
      if (textRef.current) {
        const element = textRef.current;
        const actualHeight = element.scrollHeight;
        const visibleHeight = element.clientHeight;
        setIsTruncated(actualHeight > visibleHeight + 5);
      }
    };
    const timeoutId = setTimeout(checkTruncation, 0);
    return () => clearTimeout(timeoutId);
  }, [text, lines, cleanNewLines]);
  const cleanedText = cleanText(text);
  if (!cleanedText) return null;
  return <>
      <Typography ref={textRef} gutterBottom variant="body1" color="inherit" {...typographyProps} sx={{
      textDecoration: 'none',
      margin: 'auto',
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      '& a': {
        color: isDarkMode ? theme.palette.secondary.main : theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      },
      ...(cleanNewLines ? {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: lines
      } : {
        overflow: 'visible'
      }),
      whiteSpace: 'pre-line',
      ...sx
    }} data-sentry-element="Typography" data-sentry-source-file="TruncateText.tsx">
        <Markup mentions={mentions} data-sentry-element="Markup" data-sentry-source-file="TruncateText.tsx">{cleanedText}</Markup>
      </Typography>
      {isTruncated && cleanNewLines && showReadMore && <Link href={postDetailPath} passHref>
          <MuiLink underline="hover" component="span" sx={{
        marginLeft: '4px'
      }}>
            ... read more
          </MuiLink>
        </Link>}
    </>;
};
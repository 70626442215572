// ** Mui Imports
import { Modal, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IconX } from '@tabler/icons-react';

// ** Hooks
import { useModalsActions, useModalsState } from '@/context/modals';

// ** Components
import BaseModal from './base';
import { LoginWallet } from '../ctaButtons/wallet/LoginWallet';
export const ConnectWalletModal = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {
    connectWallet
  } = useModalsState();
  const {
    isOpen
  } = connectWallet;
  const {
    close
  } = useModalsActions();
  const handleCloseModal = () => {
    close('connectWallet');
  };
  return <>
      <Modal open={isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-source-file="connectWallet.tsx">
        <BaseModal title="Login with Lens" action={<IconX onClick={handleCloseModal} style={{
        cursor: 'pointer'
      }} />} sx={{
        minHeight: '10rem',
        justifyContent: 'center'
      }} data-sentry-element="BaseModal" data-sentry-source-file="connectWallet.tsx">
          <>
            <Stack width={'100%'} direction={'column'} alignItems={'center'} justifyContent={'center'} gap={0.5} sx={{
            my: 2
          }} data-sentry-element="Stack" data-sentry-source-file="connectWallet.tsx">
              <Typography variant="h6" component="h2" textAlign={'center'} data-sentry-element="Typography" data-sentry-source-file="connectWallet.tsx">
                Connect your wallet.
              </Typography>
              <Typography variant="body1" textAlign={'center'} data-sentry-element="Typography" data-sentry-source-file="connectWallet.tsx">
                Connect your wallet to continue.
              </Typography>
            </Stack>
            <Stack direction={'column'} maxHeight={isSmScreen ? '10rem' : '15rem'} sx={{
            overflowY: 'auto',
            mt: 2
          }} data-sentry-element="Stack" data-sentry-source-file="connectWallet.tsx">
              <LoginWallet beforeOpen={handleCloseModal} data-sentry-element="LoginWallet" data-sentry-source-file="connectWallet.tsx" />
            </Stack>
          </>
        </BaseModal>
      </Modal>
    </>;
};
// ** React Imports
import { useEffect, useState } from 'react';

// ** Mui Imports
import { Box, Modal, Stack } from '@mui/material';
import { IconX } from '@tabler/icons-react';

// ** Hooks
import { useModalsActions, useModalsState } from '@/context/modals';
import { useLens } from '@/context/lens';
import { useAccount } from 'wagmi';

// ** Components
import { LensProfileData, LensSignature } from './LensSignature';
import BaseModal from '../base';
import { SkeletonSignature } from './SkeletonSignature';
import { getSignInStatus, setSignInStatus } from '@/utils/helpers';
import { SignInStatus } from '@/types/custom';
import { LensEnableManager } from './LensEnableManager';
import { LoginWallet } from '@/components/ctaButtons/wallet/LoginWallet';
import { usePrivy } from '@privy-io/react-auth';
export type SignatureStatus = 'pending' | 'success' | 'error' | 'disabled' | undefined;

//////////////////////////////////
export const SignInProcessModal = () => {
  const [lensSign, setLensSign] = useState<SignatureStatus>('disabled');
  const [enableManager, setEnableManager] = useState<SignatureStatus>('disabled');
  const [lensProfiles, setLensProfiles] = useState<LensProfileData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [renderEnableManager, setRenderEnableManager] = useState(false);
  const {
    signInProcess
  } = useModalsState();
  const {
    isOpen
  } = signInProcess;
  const {
    auth: lensAuth,
    getManagedProfiles,
    isManagerEnabled
  } = useLens();
  const {
    close
  } = useModalsActions();
  const {
    address: wagmiAddress
  } = useAccount();
  const {
    user: privyUser
  } = usePrivy();
  const address = privyUser?.wallet?.address || wagmiAddress;
  useEffect(() => {
    if (!isOpen) return;
    let isLensSuccess = false;
    let isLensManagerEnabled = false;
    if (lensAuth) {
      setLensSign('success');
      isLensSuccess = true;
      if (isManagerEnabled === null) return;
      if (isManagerEnabled) {
        setEnableManager('success');
        isLensManagerEnabled = true;
      } else {
        setEnableManager(undefined);
        isLensManagerEnabled = false;
        setRenderEnableManager(true);
      }
    } else {
      setLensSign(undefined);
      setEnableManager('disabled');
      getUserProfiles();
      isLensSuccess = false;
      isLensManagerEnabled = false;
    }
    if (isLensSuccess && isLensManagerEnabled) {
      handleSignInComplete();
    } else {
      setSignInStatus(SignInStatus.pending);
    }
    return () => {
      cleanState();
    };
  }, [isOpen, lensAuth, isManagerEnabled]);
  const getUserProfiles = async () => {
    if (!address) return;
    try {
      setIsLoading(true);
      const managedProfiles = await getManagedProfiles(address);
      if (managedProfiles === undefined) {
        setLensProfiles([]);
        return;
      }
      const userManagedProfiles = managedProfiles.items;
      const profiles = userManagedProfiles.map(profile => {
        return {
          id: profile.id,
          handle: profile.handle?.localName || '',
          name: profile.metadata?.displayName || '',
          img: profile.metadata?.picture && 'optimized' in profile.metadata.picture ? profile.metadata.picture.optimized?.uri || '' : ''
        };
      });
      setLensProfiles(profiles);
    } catch (error) {
      console.log('Error getting lens profiles at modal', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleCloseModal = () => {
    const signInStatus = getSignInStatus();
    if (signInStatus !== SignInStatus.completed) return;
    close('signInProcess');
  };
  const handleCloseManually = () => {
    close('signInProcess');
    if (!lensSign) {
      setSignInStatus(SignInStatus.rejected);
    }
  };
  const handleSignInComplete = () => {
    setSignInStatus(SignInStatus.completed);
    handleCloseModal();
  };
  const cleanState = () => {
    setLensProfiles([]);
  };
  return <>
      <Modal open={isOpen} onClose={handleCloseModal} data-sentry-element="Modal" data-sentry-source-file="index.tsx">
        <BaseModal title="Login" action={<IconX onClick={handleCloseManually} style={{
        cursor: 'pointer',
        display: 'none'
      }} />} footerContent={<Box display={'flex'} justifyContent={'flex-end'}>
              <LoginWallet variant="contained" size="small" color="inherit" label="Change wallet" beforeOpen={() => close('signInProcess')} changeWallet />
            </Box>} sx={{
        minHeight: '10rem',
        justifyContent: 'center'
      }} data-sentry-element="BaseModal" data-sentry-source-file="index.tsx">
          <>
            <Stack width={'100%'} direction={'column'} alignItems={'center'} justifyContent={'center'} gap={1} mt={1} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
              {isLoading ? <SkeletonSignature /> : <>
                  <LensSignature profiles={lensProfiles} status={lensSign} setStatus={setLensSign} />
                  {renderEnableManager ? <LensEnableManager status={enableManager} setStatus={setEnableManager} /> : null}
                </>}
            </Stack>
          </>
        </BaseModal>
      </Modal>
    </>;
};
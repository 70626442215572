import { Chain } from 'viem'
import { POLYGON_ID } from './networks'
import { ProjectModeType } from '@/types/custom'

export const PROJECT_MODE: ProjectModeType =
  (process.env.NEXT_PUBLIC_PROJECT_MODE as ProjectModeType) ?? 'development'

/* BASE URLs ======================================================================== */

export const BACKEND_URL =
  process.env.NEXT_PUBLIC_BACKEND_URL || 'http://localhost:3000'

export const ALCHEMY_API_KEY = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY || ''

export const W3M_PROJECT_ID = process.env.NEXT_PUBLIC_PROJECT_ID || ''

export const HOTJAR_PROJECT_ID: number =
  Number(process.env.NEXT_PUBLIC_HOTJAR_PROJECT_ID) || 0

export const PINNING_PINATA_API_URL = 'https://api.pinata.cloud/pinning'

export const PRIVY_APP_ID = process.env.NEXT_PUBLIC_PRIVY_APP_ID || ''

export const HANDLE_FINDER_ADDRESS =
  process.env.NEXT_PUBLIC_HANDLE_FINDER_ADDRESS || ''

export const FARCASTER_HUB_URL =
  process.env.NEXT_PUBLIC_FARCASTER_HUB_URL || ''

/* URLs PER NETWORK ================================================================= */

export const LENS_API_URL = {
  [POLYGON_ID]: 'https://api-v2.lens.dev',
}

/* API ENDPOINTS ==================================================================== */

type ApiEndpoint = 'lens'

type NetworkApiEndpoints = {
  [chainId in Chain['id']]?: {
    [key in ApiEndpoint]: string
  }
}

export const ALCHEMY_API_ENDPOINT = {
  [POLYGON_ID]: 'https://polygon-mainnet.g.alchemy.com/v2',
}

export const NETWORK_API_ENDPOINTS: NetworkApiEndpoints = {
  [POLYGON_ID]: {
    lens: LENS_API_URL[POLYGON_ID],
  },
}

/* .env TOKENS ====================================================================== */

export const PINATA_JWT = process.env.NEXT_PUBLIC_PINATA_JWT || ''

/* Gateways ========================================================================= */

export const pinataCloudGateway = (cid: string) =>
  cid ? `https://gateway.pinata.cloud/ipfs/${cid}` : ''

export const nftStorageGateway = (cid: string) =>
  cid ? `https://${cid}.ipfs.nftstorage.link` : ''

export const dwebGateway = (cid: string) =>
  cid ? `https://${cid}.ipfs.dweb.link` : ''

export const getIPFSGateway = (cid: string) =>
  cid ? `https://ipfs.io/ipfs/${cid}` : ''

export const getCidFromGateway = (url: string) => {
  if (url.startsWith('ipfs://')) {
    return url.substring(7)
  }

  const regex = /\/ipfs\/(.+)$/
  const match = url.match(regex)

  if (match && match[1]) {
    return match[1]
  }
  return null
}

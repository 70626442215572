// ** MUI Imports
import { Button } from '@mui/material';

// ** Hooks
import { useModalsActions } from '@/context/modals';

// ** Types
import { BaseMUIButtonProps } from '@/types/custom';
type Props = BaseMUIButtonProps & {
  /**
   * The text to be displayed inside the button.
   * @default "Sign In"
   */
  label?: string;
  /**
   * Callback fired before the subscription takes place.
   */
  beforeOpen?: () => void;
};
export const SignInBtn = ({
  variant = 'text',
  color = 'primary',
  size = 'medium',
  label = 'Sign In',
  beforeOpen
}: Props) => {
  const {
    open: openModal
  } = useModalsActions();
  const logInWithLens = () => {
    openModal('signInProcess');
  };
  return <Button variant={variant} color={color} size={size} onClick={() => {
    beforeOpen?.();
    logInWithLens();
  }} data-sentry-element="Button" data-sentry-component="SignInBtn" data-sentry-source-file="SignInWallet.tsx">
   {label}
  </Button>;
};
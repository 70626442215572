import { CollectActionModuleSettings } from '@/context/lens'
import { PostFragment } from '@lens-protocol/client'

export enum GroupBy {
  appId = 'appId',
  interest = 'interest',
  activity = 'activity',
}

export interface ChartData {
  x: number | string
  y: number | string
  label: string
}

export const categories = [
  'Personal Hobbies and Interests',
  'Health and Wellness',
  'Technology',
  'Cryptocurrencies',
  'Business and Economy',
  'Home and Garden',
  'Food and Drink',
  'Art and Entertainment',
  'News and Politics',
  'Education',
  'Family and Parenting',
  'Not Safe for Work (NSFW)',
]

export const categoryKeywords = {
  'Personal Hobbies and Interests': [
    'hobbies',
    'interests',
    'collecting',
    'cars',
    'travel',
    'sports',
    'gaming',
  ],
  'Health and Wellness': ['health', 'fitness', 'exercise', 'biohacking'],
  Technology: ['technology', 'ai', 'ml', 'programming', 'biotech', 'tools'],
  Cryptocurrencies: [
    'crypto',
    'bitcoin',
    'ethereum',
    'l2',
    'l1',
    'defi',
    'nft',
    'scaling',
    'web3',
    'web3 social',
    'rekt',
    'gm',
    'daos',
    'governance',
    'metaverse',
  ],
  'Business and Economy': [
    'business',
    'finance',
    'creator economy',
    'marketing',
    'regulation',
  ],
  'Home and Garden': [
    'home',
    'garden',
    'nature',
    'home improvement',
    'animals',
  ],
  'Food and Drink': [
    'food',
    'drink',
    'restaurants',
    'wine',
    'beer',
    'cocktails',
    'cooking',
  ],
  'Art and Entertainment': [
    'art',
    'entertainment',
    'books',
    'fashion',
    'design',
    'film',
    'tv',
    'music',
    'anime',
    'meme',
  ],
  'News and Politics': ['news', 'politics'],
  Education: ['education'],
  'Family and Parenting': ['family', 'parenting'],
  'Not Safe for Work (NSFW)': ['nsfw'],
}

export interface ExplorePublicationsResponse {
  explorePublications: {
    items: Array<{
      id: string
      by: {
        id: string
        handle: {
          localName: string
        }
        metadata: {
          displayName: string
          bio: string
          picture: {
            optimized: {
              uri: string
            }
          }
        }
        stats: {
          lensClassifierScore: number
          followers: string
        }
      }
      createdAt: string
      stats: {
        bookmarks: number
        comments: number
        mirrors: number
        quotes: number
        reactions: number
        countOpenActions: number
      }
    }>
    pageInfo: {
      next: string | null
      prev: string | null
    }
  }
}

export interface ExplorePublicationsVariables {
  request: {
    limit: string
    orderBy: string
    where: {
      publicationTypes: string
      metadata: {
        tags: {
          oneOf: string[]
        }
        publishedOn: string[]
      }
    }
  }
}

export interface TopPost {
  publicationId: string
}

export interface ProfileEngagement {
  profileId: string
  totalEngagement: number
  reactions: number
  comments: number
  mirrors: number
  quotes: number
}

export interface EngagementResult {
  topPosts: TopPost[]
  topEngagedProfiles: ProfileEngagement[]
}

interface UserHandle {
  localName: string
}

interface UserMetadata {
  displayName: string
  bio: string
  picture: {
    optimized: {
      uri: string
    }
  }
}

export interface MostActiveUser {
  userId: string
  postCount: number
  totalReactions: number
  handle: UserHandle
  metadata: UserMetadata
}

export interface TopUser {
  userId: string
  lensClassifierScore: number
  followers: number
  handle: UserHandle
  metadata: UserMetadata
}

export interface PostsPerDay {
  day: string
  postCount: number
}

export interface ZoraCurrency {
  currency: string
  symbol: string
  decimals: number
}

export const backendCurrencies: ZoraCurrency[] = [
  {
    currency: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
    symbol: 'USDC',
    decimals: 6,
  },
  {
    currency: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    symbol: 'WPOL',
    decimals: 18,
  },
  {
    currency: '0x3d2bd0e15829aa5c362a4144fdf4a1112fa29b5c',
    symbol: 'BONSAI',
    decimals: 18,
  },
  {
    currency: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    symbol: 'USDC.E',
    decimals: 6,
  },
  {
    currency: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
    symbol: 'USDT',
    decimals: 6,
  },
]

export type CollectCriteria = {
  canCollect: string
  settings: CollectActionModuleSettings
  post: PostFragment
}

export const goodSentiment = ['Happy', 'Excited']
export const badSentiment = ['Anxious', 'Bored', 'Angry']

export interface TopicSentiment {
  sentiment: string
  value: number
}

export interface Topic {
  id: string
  topic: string
  description: string
  comments: number
  apperances: number
  date: string
  topicSentiment: TopicSentiment[]
}

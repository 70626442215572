import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSession } from './session';
import { CategoryType } from '@/types/custom';
import { useBackend } from './backend';
type CategoryFilterContextType = {
  selectedCategories: CategoryType[];
  selectedPeopleCategories: CategoryType[];
  handleStoreCategories: (categories: CategoryType[], component?: string) => Promise<void>;
  handleRemoveCategories: (categoriesId: number[], component?: string) => Promise<void>;
  refreshNeeded: boolean;
};
const CategoryFilterContext = createContext<CategoryFilterContextType>({} as CategoryFilterContextType);
export const CategoryFilterProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const [refreshNeeded, setRefreshNeeded] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<CategoryType[]>([]);
  const [selectedPeopleCategories, setSelectedPeopleCategories] = useState<CategoryType[]>([]);
  const {
    fetchUserCategories,
    storeUserCategories,
    removeUserCategories
  } = useBackend();
  const {
    user
  } = useSession();
  const profileId = user?.profileId;
  useEffect(() => {
    if (profileId) {
      handleInitialCategories(profileId);
    }
  }, [profileId]);
  const handleInitialCategories = async (loggedProfileId: string) => {
    const categories = await fetchUserCategories(loggedProfileId);
    setSelectedCategories(categories?.categories || []);
  };
  const handleStoreCategories = async (categories: CategoryType[], component?: string) => {
    if (!profileId && component !== 'people') {
      setSelectedCategories(categories);
      return;
    }
    if (component === 'people') {
      setSelectedPeopleCategories([...selectedPeopleCategories, ...categories.filter(c => !selectedPeopleCategories.includes(c))]);
      setRefreshNeeded(!refreshNeeded);
    } else {
      const categoriesIdsToStore = categories.map(category => category.id);
      if (categoriesIdsToStore.length === 0) return;
      const response = await storeUserCategories(profileId, categoriesIdsToStore);
      if (response?.success) {
        setSelectedCategories(response.categories);
        setRefreshNeeded(!refreshNeeded);
      }
    }
  };
  const handleRemoveCategories = async (categoriesId: number[], component?: string) => {
    if (!profileId && component !== 'people') {
      setSelectedCategories(selectedCategories.filter(c => !categoriesId.includes(c.id)));
      return;
    }
    if (component === 'people') {
      setSelectedPeopleCategories(selectedPeopleCategories.filter(c => !categoriesId.includes(c.id)));
      setRefreshNeeded(!refreshNeeded);
    } else {
      const response = await removeUserCategories(profileId, categoriesId);
      if (response?.success) {
        setSelectedCategories(selectedCategories.filter(c => !categoriesId.includes(c.id)));
        setRefreshNeeded(!refreshNeeded);
      }
    }
  };
  return <CategoryFilterContext.Provider value={{
    selectedCategories,
    selectedPeopleCategories,
    handleStoreCategories,
    handleRemoveCategories,
    refreshNeeded
  }} data-sentry-element="unknown" data-sentry-component="CategoryFilterProvider" data-sentry-source-file="categoryFilter.tsx">
      {children}
    </CategoryFilterContext.Provider>;
};
export const useCategoryFilter = () => {
  return useContext(CategoryFilterContext);
};
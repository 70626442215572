export interface registerType {
  title?: string;
  subtitle?: JSX.Element | JSX.Element[];
  subtext?: JSX.Element | JSX.Element[];
}

export interface loginType {
  title?: string;
  subtitle?: JSX.Element | JSX.Element[];
  subtext?: JSX.Element | JSX.Element[];
}

export interface signInType {
  title?: string;
}

export enum CredentialType {
  all = "all",
  lens = "lens",
}

export type LensCredentialValue = {
  accessToken: string;
  message: string;
};

export type LensMutationRefresh = {
  accessToken: string;
  refreshToken: string;
  identityToken: string;
};

export type LensClientStorageValue = {
  data: {
    refreshToken: string;
  },
  metadata: {
    createdAt: number;
    updatedAt: number;
    version: 2;
  }
};

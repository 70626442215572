import { Button, ButtonProps } from '@mui/material';
import { useFarcaster } from '@/hooks/useFarcaster';
type AuthorizeFarcasterProps = {
  label?: string;
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
};
export const AuthorizeFarcaster = ({
  label = 'Authorize',
  color = 'primary',
  size = 'medium'
}: AuthorizeFarcasterProps) => {
  const {
    farcasterAccount,
    authorizeFarcaster
  } = useFarcaster();
  const handleAuthorize = async () => {
    try {
      await authorizeFarcaster();
    } catch (error) {
      console.log('Error authorizing farcaster: ', error);
    }
  };
  return <>
      <Button variant="contained" size={size} color={color} onClick={handleAuthorize} disabled={!farcasterAccount || Boolean(farcasterAccount.signerPublicKey)} data-sentry-element="Button" data-sentry-source-file="AuthorizeFarcaster.tsx">
        {label}
      </Button>
    </>;
};
// ** React Imports
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { zora } from 'viem/chains';
import { CollectorClient, createCollectorClient } from '@zoralabs/protocol-sdk';
import { usePublicClient, useWriteContract } from 'wagmi';
import { MintableReturn, MintCosts } from '@zoralabs/protocol-sdk/dist/mint/types';
import { SimulateContractParametersWithAccount } from '@zoralabs/protocol-sdk/dist/types';
import { MonitorMessage } from '@/utils/error';

/**
 * Zora interface
 */
interface Zora {
  client: CollectorClient | null;
  mint: (minterAccount: `0x${string}`, parameters: SimulateContractParametersWithAccount, costs: MintCosts) => Promise<`0x${string}` | undefined>;
  getTokenData: (tokenContract: `0x${string}`, tokenId: string) => Promise<MintableReturn | undefined>;
}
const ZoraContext = createContext<Zora>({} as Zora);

/**
 * Zora context provider
 */
export function ZoraProvider({
  children
}: {
  children: ReactNode;
}) {
  const [client, setClient] = useState<CollectorClient | null>(null);
  const {
    writeContractAsync
  } = useWriteContract();
  const publicClient = usePublicClient();
  useEffect(() => {
    if (publicClient) {
      configZoraClient();
    }
  }, [publicClient]);
  const configZoraClient = () => {
    try {
      if (!publicClient) return;
      const collectorClient = createCollectorClient({
        chainId: zora.id,
        publicClient
      });
      setClient(collectorClient);
    } catch (error) {
      console.log(`Error initializing zora client: ${error}`);
    }
  };
  const getTokenData = async (tokenContract: `0x${string}`, tokenId: string) => {
    if (!client) return;
    try {
      return await client.getToken({
        tokenContract,
        mintType: '1155',
        tokenId: tokenId
      });
    } catch (error) {
      console.log(`Error getting token metadata: ${error}`);
    }
  };
  const mint = async (minterAccount: `0x${string}`, parameters: SimulateContractParametersWithAccount, costs: MintCosts) => {
    if (!client) return;
    try {
      const balance = await publicClient?.getBalance({
        address: minterAccount
      });
      if (Number(balance) < Number(costs.totalCostEth)) {
        throw new Error('Insufficient balance');
      }
      const tx = await writeContractAsync(parameters);
      new MonitorMessage(`ZORA MINT event`, 'info');
      return tx;
    } catch (error) {
      console.log(`Error minting token ${error}`);
      throw error;
    }
  };
  const value: Zora = {
    client,
    mint,
    getTokenData
  };
  return <ZoraContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="ZoraProvider" data-sentry-source-file="zora.tsx">{children}</ZoraContext.Provider>;
}
export function useZora() {
  return useContext(ZoraContext);
}
import React from "react";
import { useState } from "react";
import { useSelector } from "../../../../../store/Store";
import { useRouter } from "next/router";

// mui imports
import { ListItemIcon, ListItemButton, Collapse, styled, ListItemText, useTheme } from "@mui/material";

// custom imports
import NavItem from "../NavItem";

// plugins
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { AppState } from "../../../../../store/Store";
type NavGroupProps = {
  [x: string]: any;
  navlabel?: boolean;
  subheader?: string;
  title?: string;
  icon?: any;
  href?: any;
};
interface NavCollapseProps {
  menu: NavGroupProps;
  level: number;
  pathWithoutLastPart: any;
  pathDirect: any;
  hideMenu: any;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

// FC Component For Dropdown Menu
const NavCollapse = ({
  menu,
  level,
  pathWithoutLastPart,
  pathDirect,
  hideMenu,
  onClick
}: NavCollapseProps) => {
  const customizer = useSelector((state: AppState) => state.customizer);
  const Icon = menu?.icon;
  const theme = useTheme();
  const {
    pathname
  } = useRouter();
  const {
    t
  } = useTranslation();
  const [open, setOpen] = useState(true);
  const menuIcon = level > 1 ? <Icon stroke={1.5} size="1rem" /> : <Icon stroke={1.5} size="1.3rem" />;
  const handleClick = () => {
    setOpen(!open);
  };

  // menu collapse for sub-levels
  React.useEffect(() => {
    setOpen(false);
    menu?.children?.forEach((item: any) => {
      if (item?.href === pathname) {
        setOpen(true);
      }
    });
  }, [pathname, menu.children]);
  const ListItemStyled = styled(ListItemButton)(() => ({
    marginBottom: "2px",
    padding: "8px 10px",
    paddingLeft: hideMenu ? "10px" : level > 2 ? `${level * 15}px` : "10px",
    backgroundColor: open && level < 2 ? theme.palette.primary.main : "",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: pathname.includes(menu.href) || open ? theme.palette.primary.main : theme.palette.primary.light,
      color: pathname.includes(menu.href) || open ? "white" : theme.palette.primary.main
    },
    color: open && level < 2 ? "white" : `inherit` && level > 1 && open ? theme.palette.primary.main : theme.palette.text.secondary,
    borderRadius: `${customizer.borderRadius}px`
  }));

  // If Menu has Children
  const submenus = menu.children?.map((item: any) => {
    if (item.children) {
      return <NavCollapse key={item?.id} menu={item} level={level + 1} pathWithoutLastPart={pathWithoutLastPart} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
    } else {
      return <NavItem key={item.id} item={item} level={level + 1} pathDirect={pathDirect} hideMenu={hideMenu} onClick={onClick} />;
    }
  });
  return <>
      <ListItemStyled onClick={handleClick} selected={pathWithoutLastPart === menu.href} key={menu?.id} data-sentry-element="ListItemStyled" data-sentry-source-file="index.tsx">
        <ListItemIcon sx={{
        minWidth: "36px",
        p: "3px 0",
        color: "inherit"
      }} data-sentry-element="ListItemIcon" data-sentry-source-file="index.tsx">
          {menuIcon}
        </ListItemIcon>
        <ListItemText color="inherit" data-sentry-element="ListItemText" data-sentry-source-file="index.tsx">
          {hideMenu ? "" : <>{t(`${menu.title}`)}</>}
        </ListItemText>
        {!open ? <IconChevronDown size="1rem" /> : <IconChevronUp size="1rem" />}
      </ListItemStyled>
      <Collapse in={open} timeout="auto" unmountOnExit data-sentry-element="Collapse" data-sentry-source-file="index.tsx">
        {submenus}
      </Collapse>
    </>;
};
export default NavCollapse;